var vi = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
};
function mi(e) {
  return e;
}
function Y(e, t, r, n, s, i, o, c, u) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return s(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return i(s(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return o(i(s(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return c(o(i(s(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return u(c(o(i(s(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function g(e, t, r, n, s, i, o, c, u) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return s(n(r(t(e))));
    case 6:
      return i(s(n(r(t(e)))));
    case 7:
      return o(i(s(n(r(t(e))))));
    case 8:
      return c(o(i(s(n(r(t(e)))))));
    case 9:
      return u(c(o(i(s(n(r(t(e))))))));
    default: {
      for (var l = arguments[0], f = 1; f < arguments.length; f++)
        l = arguments[f](l);
      return l;
    }
  }
}
var yi = function(e, t) {
  var r = function(n) {
    return n.length >= e;
  };
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(s) {
      return t.apply(void 0, vi([s], n, !1));
    };
  };
}, gi = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, _i = function(e) {
  return gi(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
}, na = function(e) {
  return e._tag === "Some";
}, aa = { _tag: "None" }, sa = function(e) {
  return { _tag: "Some", value: e };
}, bi = function(e) {
  return e._tag === "Left";
}, Ti = function(e) {
  return e._tag === "Right";
}, $i = function(e) {
  return { _tag: "Left", left: e };
}, Ai = function(e) {
  return { _tag: "Right", right: e };
}, Si = function(e) {
  return [e];
}, Ei = [], xi = Object.prototype.hasOwnProperty, wi = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, s) {
        return e.concat(n, s);
      }, t);
    };
  };
}, Ri = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var s in e)
        xi.call(e, s) && (n[s] = e[s].concat(t[s], r[s]));
      return n;
    }
  };
}, cr = function() {
  return { concat: mi };
}, ki = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, ji = wi, cn = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, Oi = Ei, Ci = function(e, t) {
  return e < 0 || e >= t.length;
}, Ii = function(e) {
  return function(t) {
    return cn(cn([], t, !0), [e], !1);
  };
}, Ni = Ii, Mi = Si, Q = {
  equals: function(e, t) {
    return e === t;
  }
}, Pi = {
  concat: function(e, t) {
    return e + t;
  }
}, Vi = "", Rr = {
  concat: Pi.concat,
  empty: Vi
};
Q.equals;
var ia = function(e) {
  return e.trim();
}, oa = function(e) {
  return e.length === 0;
}, _t = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function ca(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function Di() {
  this.__data__ = [], this.size = 0;
}
var Li = Di;
function Ui(e, t) {
  return e === t || e !== e && t !== t;
}
var kr = Ui, Hi = kr;
function Zi(e, t) {
  for (var r = e.length; r--; )
    if (Hi(e[r][0], t))
      return r;
  return -1;
}
var Dt = Zi, qi = Dt, Bi = Array.prototype, Gi = Bi.splice;
function zi(e) {
  var t = this.__data__, r = qi(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : Gi.call(t, r, 1), --this.size, !0;
}
var Fi = zi, Ki = Dt;
function Wi(e) {
  var t = this.__data__, r = Ki(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var Qi = Wi, Ji = Dt;
function Yi(e) {
  return Ji(this.__data__, e) > -1;
}
var Xi = Yi, eo = Dt;
function to(e, t) {
  var r = this.__data__, n = eo(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var ro = to, no = Li, ao = Fi, so = Qi, io = Xi, oo = ro;
function Ie(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Ie.prototype.clear = no;
Ie.prototype.delete = ao;
Ie.prototype.get = so;
Ie.prototype.has = io;
Ie.prototype.set = oo;
var Lt = Ie, co = Lt;
function uo() {
  this.__data__ = new co(), this.size = 0;
}
var lo = uo;
function fo(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var po = fo;
function ho(e) {
  return this.__data__.get(e);
}
var vo = ho;
function mo(e) {
  return this.__data__.has(e);
}
var yo = mo, go = typeof _t == "object" && _t && _t.Object === Object && _t, ua = go, _o = ua, bo = typeof self == "object" && self && self.Object === Object && self, To = _o || bo || Function("return this")(), F = To, $o = F, Ao = $o.Symbol, Ut = Ao, un = Ut, la = Object.prototype, So = la.hasOwnProperty, Eo = la.toString, Le = un ? un.toStringTag : void 0;
function xo(e) {
  var t = So.call(e, Le), r = e[Le];
  try {
    e[Le] = void 0;
    var n = !0;
  } catch {
  }
  var s = Eo.call(e);
  return n && (t ? e[Le] = r : delete e[Le]), s;
}
var wo = xo, Ro = Object.prototype, ko = Ro.toString;
function jo(e) {
  return ko.call(e);
}
var Oo = jo, ln = Ut, Co = wo, Io = Oo, No = "[object Null]", Mo = "[object Undefined]", dn = ln ? ln.toStringTag : void 0;
function Po(e) {
  return e == null ? e === void 0 ? Mo : No : dn && dn in Object(e) ? Co(e) : Io(e);
}
var Ht = Po;
function Vo(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var nt = Vo, Do = Ht, Lo = nt, Uo = "[object AsyncFunction]", Ho = "[object Function]", Zo = "[object GeneratorFunction]", qo = "[object Proxy]";
function Bo(e) {
  if (!Lo(e))
    return !1;
  var t = Do(e);
  return t == Ho || t == Zo || t == Uo || t == qo;
}
var da = Bo, Go = F, zo = Go["__core-js_shared__"], Fo = zo, ur = Fo, fn = function() {
  var e = /[^.]+$/.exec(ur && ur.keys && ur.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Ko(e) {
  return !!fn && fn in e;
}
var Wo = Ko, Qo = Function.prototype, Jo = Qo.toString;
function Yo(e) {
  if (e != null) {
    try {
      return Jo.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var fa = Yo, Xo = da, ec = Wo, tc = nt, rc = fa, nc = /[\\^$.*+?()[\]{}|]/g, ac = /^\[object .+?Constructor\]$/, sc = Function.prototype, ic = Object.prototype, oc = sc.toString, cc = ic.hasOwnProperty, uc = RegExp(
  "^" + oc.call(cc).replace(nc, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function lc(e) {
  if (!tc(e) || ec(e))
    return !1;
  var t = Xo(e) ? uc : ac;
  return t.test(rc(e));
}
var dc = lc;
function fc(e, t) {
  return e == null ? void 0 : e[t];
}
var pc = fc, hc = dc, vc = pc;
function mc(e, t) {
  var r = vc(e, t);
  return hc(r) ? r : void 0;
}
var de = mc, yc = de, gc = F, _c = yc(gc, "Map"), jr = _c, bc = de, Tc = bc(Object, "create"), Zt = Tc, pn = Zt;
function $c() {
  this.__data__ = pn ? pn(null) : {}, this.size = 0;
}
var Ac = $c;
function Sc(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Ec = Sc, xc = Zt, wc = "__lodash_hash_undefined__", Rc = Object.prototype, kc = Rc.hasOwnProperty;
function jc(e) {
  var t = this.__data__;
  if (xc) {
    var r = t[e];
    return r === wc ? void 0 : r;
  }
  return kc.call(t, e) ? t[e] : void 0;
}
var Oc = jc, Cc = Zt, Ic = Object.prototype, Nc = Ic.hasOwnProperty;
function Mc(e) {
  var t = this.__data__;
  return Cc ? t[e] !== void 0 : Nc.call(t, e);
}
var Pc = Mc, Vc = Zt, Dc = "__lodash_hash_undefined__";
function Lc(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = Vc && t === void 0 ? Dc : t, this;
}
var Uc = Lc, Hc = Ac, Zc = Ec, qc = Oc, Bc = Pc, Gc = Uc;
function Ne(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Ne.prototype.clear = Hc;
Ne.prototype.delete = Zc;
Ne.prototype.get = qc;
Ne.prototype.has = Bc;
Ne.prototype.set = Gc;
var zc = Ne, hn = zc, Fc = Lt, Kc = jr;
function Wc() {
  this.size = 0, this.__data__ = {
    hash: new hn(),
    map: new (Kc || Fc)(),
    string: new hn()
  };
}
var Qc = Wc;
function Jc(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Yc = Jc, Xc = Yc;
function eu(e, t) {
  var r = e.__data__;
  return Xc(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var qt = eu, tu = qt;
function ru(e) {
  var t = tu(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var nu = ru, au = qt;
function su(e) {
  return au(this, e).get(e);
}
var iu = su, ou = qt;
function cu(e) {
  return ou(this, e).has(e);
}
var uu = cu, lu = qt;
function du(e, t) {
  var r = lu(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var fu = du, pu = Qc, hu = nu, vu = iu, mu = uu, yu = fu;
function Me(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Me.prototype.clear = pu;
Me.prototype.delete = hu;
Me.prototype.get = vu;
Me.prototype.has = mu;
Me.prototype.set = yu;
var pa = Me, gu = Lt, _u = jr, bu = pa, Tu = 200;
function $u(e, t) {
  var r = this.__data__;
  if (r instanceof gu) {
    var n = r.__data__;
    if (!_u || n.length < Tu - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new bu(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var Au = $u, Su = Lt, Eu = lo, xu = po, wu = vo, Ru = yo, ku = Au;
function Pe(e) {
  var t = this.__data__ = new Su(e);
  this.size = t.size;
}
Pe.prototype.clear = Eu;
Pe.prototype.delete = xu;
Pe.prototype.get = wu;
Pe.prototype.has = Ru;
Pe.prototype.set = ku;
var ha = Pe;
function ju(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var Ou = ju, Cu = de, Iu = function() {
  try {
    var e = Cu(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), Nu = Iu, vn = Nu;
function Mu(e, t, r) {
  t == "__proto__" && vn ? vn(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var va = Mu, Pu = va, Vu = kr, Du = Object.prototype, Lu = Du.hasOwnProperty;
function Uu(e, t, r) {
  var n = e[t];
  (!(Lu.call(e, t) && Vu(n, r)) || r === void 0 && !(t in e)) && Pu(e, t, r);
}
var ma = Uu, Hu = ma, Zu = va;
function qu(e, t, r, n) {
  var s = !r;
  r || (r = {});
  for (var i = -1, o = t.length; ++i < o; ) {
    var c = t[i], u = n ? n(r[c], e[c], c, r, e) : void 0;
    u === void 0 && (u = e[c]), s ? Zu(r, c, u) : Hu(r, c, u);
  }
  return r;
}
var Bt = qu;
function Bu(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var Gu = Bu;
function zu(e) {
  return e != null && typeof e == "object";
}
var Ve = zu, Fu = Ht, Ku = Ve, Wu = "[object Arguments]";
function Qu(e) {
  return Ku(e) && Fu(e) == Wu;
}
var Ju = Qu, mn = Ju, Yu = Ve, ya = Object.prototype, Xu = ya.hasOwnProperty, el = ya.propertyIsEnumerable, tl = mn(/* @__PURE__ */ function() {
  return arguments;
}()) ? mn : function(e) {
  return Yu(e) && Xu.call(e, "callee") && !el.call(e, "callee");
}, rl = tl, nl = Array.isArray, Gt = nl, At = { exports: {} };
function al() {
  return !1;
}
var sl = al;
At.exports;
(function(e, t) {
  var r = F, n = sl, s = t && !t.nodeType && t, i = s && !0 && e && !e.nodeType && e, o = i && i.exports === s, c = o ? r.Buffer : void 0, u = c ? c.isBuffer : void 0, l = u || n;
  e.exports = l;
})(At, At.exports);
var Or = At.exports, il = 9007199254740991, ol = /^(?:0|[1-9]\d*)$/;
function cl(e, t) {
  var r = typeof e;
  return t = t ?? il, !!t && (r == "number" || r != "symbol" && ol.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var ul = cl, ll = 9007199254740991;
function dl(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= ll;
}
var ga = dl, fl = Ht, pl = ga, hl = Ve, vl = "[object Arguments]", ml = "[object Array]", yl = "[object Boolean]", gl = "[object Date]", _l = "[object Error]", bl = "[object Function]", Tl = "[object Map]", $l = "[object Number]", Al = "[object Object]", Sl = "[object RegExp]", El = "[object Set]", xl = "[object String]", wl = "[object WeakMap]", Rl = "[object ArrayBuffer]", kl = "[object DataView]", jl = "[object Float32Array]", Ol = "[object Float64Array]", Cl = "[object Int8Array]", Il = "[object Int16Array]", Nl = "[object Int32Array]", Ml = "[object Uint8Array]", Pl = "[object Uint8ClampedArray]", Vl = "[object Uint16Array]", Dl = "[object Uint32Array]", x = {};
x[jl] = x[Ol] = x[Cl] = x[Il] = x[Nl] = x[Ml] = x[Pl] = x[Vl] = x[Dl] = !0;
x[vl] = x[ml] = x[Rl] = x[yl] = x[kl] = x[gl] = x[_l] = x[bl] = x[Tl] = x[$l] = x[Al] = x[Sl] = x[El] = x[xl] = x[wl] = !1;
function Ll(e) {
  return hl(e) && pl(e.length) && !!x[fl(e)];
}
var Ul = Ll;
function Hl(e) {
  return function(t) {
    return e(t);
  };
}
var Cr = Hl, St = { exports: {} };
St.exports;
(function(e, t) {
  var r = ua, n = t && !t.nodeType && t, s = n && !0 && e && !e.nodeType && e, i = s && s.exports === n, o = i && r.process, c = function() {
    try {
      var u = s && s.require && s.require("util").types;
      return u || o && o.binding && o.binding("util");
    } catch {
    }
  }();
  e.exports = c;
})(St, St.exports);
var Ir = St.exports, Zl = Ul, ql = Cr, yn = Ir, gn = yn && yn.isTypedArray, Bl = gn ? ql(gn) : Zl, _a = Bl, Gl = Gu, zl = rl, Fl = Gt, Kl = Or, Wl = ul, Ql = _a, Jl = Object.prototype, Yl = Jl.hasOwnProperty;
function Xl(e, t) {
  var r = Fl(e), n = !r && zl(e), s = !r && !n && Kl(e), i = !r && !n && !s && Ql(e), o = r || n || s || i, c = o ? Gl(e.length, String) : [], u = c.length;
  for (var l in e)
    (t || Yl.call(e, l)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (l == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    s && (l == "offset" || l == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    i && (l == "buffer" || l == "byteLength" || l == "byteOffset") || // Skip index properties.
    Wl(l, u))) && c.push(l);
  return c;
}
var ba = Xl, ed = Object.prototype;
function td(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || ed;
  return e === r;
}
var Nr = td;
function rd(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var Ta = rd, nd = Ta, ad = nd(Object.keys, Object), sd = ad, id = Nr, od = sd, cd = Object.prototype, ud = cd.hasOwnProperty;
function ld(e) {
  if (!id(e))
    return od(e);
  var t = [];
  for (var r in Object(e))
    ud.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var dd = ld, fd = da, pd = ga;
function hd(e) {
  return e != null && pd(e.length) && !fd(e);
}
var $a = hd, vd = ba, md = dd, yd = $a;
function gd(e) {
  return yd(e) ? vd(e) : md(e);
}
var Mr = gd, _d = Bt, bd = Mr;
function Td(e, t) {
  return e && _d(t, bd(t), e);
}
var $d = Td;
function Ad(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var Sd = Ad, Ed = nt, xd = Nr, wd = Sd, Rd = Object.prototype, kd = Rd.hasOwnProperty;
function jd(e) {
  if (!Ed(e))
    return wd(e);
  var t = xd(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !kd.call(e, n)) || r.push(n);
  return r;
}
var Od = jd, Cd = ba, Id = Od, Nd = $a;
function Md(e) {
  return Nd(e) ? Cd(e, !0) : Id(e);
}
var Pr = Md, Pd = Bt, Vd = Pr;
function Dd(e, t) {
  return e && Pd(t, Vd(t), e);
}
var Ld = Dd, Et = { exports: {} };
Et.exports;
(function(e, t) {
  var r = F, n = t && !t.nodeType && t, s = n && !0 && e && !e.nodeType && e, i = s && s.exports === n, o = i ? r.Buffer : void 0, c = o ? o.allocUnsafe : void 0;
  function u(l, f) {
    if (f)
      return l.slice();
    var _ = l.length, S = c ? c(_) : new l.constructor(_);
    return l.copy(S), S;
  }
  e.exports = u;
})(Et, Et.exports);
var Ud = Et.exports;
function Hd(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var Zd = Hd;
function qd(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, s = 0, i = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (i[s++] = o);
  }
  return i;
}
var Bd = qd;
function Gd() {
  return [];
}
var Aa = Gd, zd = Bd, Fd = Aa, Kd = Object.prototype, Wd = Kd.propertyIsEnumerable, _n = Object.getOwnPropertySymbols, Qd = _n ? function(e) {
  return e == null ? [] : (e = Object(e), zd(_n(e), function(t) {
    return Wd.call(e, t);
  }));
} : Fd, Vr = Qd, Jd = Bt, Yd = Vr;
function Xd(e, t) {
  return Jd(e, Yd(e), t);
}
var ef = Xd;
function tf(e, t) {
  for (var r = -1, n = t.length, s = e.length; ++r < n; )
    e[s + r] = t[r];
  return e;
}
var Sa = tf, rf = Ta, nf = rf(Object.getPrototypeOf, Object), Ea = nf, af = Sa, sf = Ea, of = Vr, cf = Aa, uf = Object.getOwnPropertySymbols, lf = uf ? function(e) {
  for (var t = []; e; )
    af(t, of(e)), e = sf(e);
  return t;
} : cf, xa = lf, df = Bt, ff = xa;
function pf(e, t) {
  return df(e, ff(e), t);
}
var hf = pf, vf = Sa, mf = Gt;
function yf(e, t, r) {
  var n = t(e);
  return mf(e) ? n : vf(n, r(e));
}
var wa = yf, gf = wa, _f = Vr, bf = Mr;
function Tf(e) {
  return gf(e, bf, _f);
}
var Ra = Tf, $f = wa, Af = xa, Sf = Pr;
function Ef(e) {
  return $f(e, Sf, Af);
}
var xf = Ef, wf = de, Rf = F, kf = wf(Rf, "DataView"), jf = kf, Of = de, Cf = F, If = Of(Cf, "Promise"), Nf = If, Mf = de, Pf = F, Vf = Mf(Pf, "Set"), Df = Vf, Lf = de, Uf = F, Hf = Lf(Uf, "WeakMap"), Zf = Hf, vr = jf, mr = jr, yr = Nf, gr = Df, _r = Zf, ka = Ht, De = fa, bn = "[object Map]", qf = "[object Object]", Tn = "[object Promise]", $n = "[object Set]", An = "[object WeakMap]", Sn = "[object DataView]", Bf = De(vr), Gf = De(mr), zf = De(yr), Ff = De(gr), Kf = De(_r), oe = ka;
(vr && oe(new vr(new ArrayBuffer(1))) != Sn || mr && oe(new mr()) != bn || yr && oe(yr.resolve()) != Tn || gr && oe(new gr()) != $n || _r && oe(new _r()) != An) && (oe = function(e) {
  var t = ka(e), r = t == qf ? e.constructor : void 0, n = r ? De(r) : "";
  if (n)
    switch (n) {
      case Bf:
        return Sn;
      case Gf:
        return bn;
      case zf:
        return Tn;
      case Ff:
        return $n;
      case Kf:
        return An;
    }
  return t;
});
var zt = oe, Wf = Object.prototype, Qf = Wf.hasOwnProperty;
function Jf(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && Qf.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var Yf = Jf, Xf = F, ep = Xf.Uint8Array, ja = ep, En = ja;
function tp(e) {
  var t = new e.constructor(e.byteLength);
  return new En(t).set(new En(e)), t;
}
var Dr = tp, rp = Dr;
function np(e, t) {
  var r = t ? rp(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var ap = np, sp = /\w*$/;
function ip(e) {
  var t = new e.constructor(e.source, sp.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var op = ip, xn = Ut, wn = xn ? xn.prototype : void 0, Rn = wn ? wn.valueOf : void 0;
function cp(e) {
  return Rn ? Object(Rn.call(e)) : {};
}
var up = cp, lp = Dr;
function dp(e, t) {
  var r = t ? lp(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var fp = dp, pp = Dr, hp = ap, vp = op, mp = up, yp = fp, gp = "[object Boolean]", _p = "[object Date]", bp = "[object Map]", Tp = "[object Number]", $p = "[object RegExp]", Ap = "[object Set]", Sp = "[object String]", Ep = "[object Symbol]", xp = "[object ArrayBuffer]", wp = "[object DataView]", Rp = "[object Float32Array]", kp = "[object Float64Array]", jp = "[object Int8Array]", Op = "[object Int16Array]", Cp = "[object Int32Array]", Ip = "[object Uint8Array]", Np = "[object Uint8ClampedArray]", Mp = "[object Uint16Array]", Pp = "[object Uint32Array]";
function Vp(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case xp:
      return pp(e);
    case gp:
    case _p:
      return new n(+e);
    case wp:
      return hp(e, r);
    case Rp:
    case kp:
    case jp:
    case Op:
    case Cp:
    case Ip:
    case Np:
    case Mp:
    case Pp:
      return yp(e, r);
    case bp:
      return new n();
    case Tp:
    case Sp:
      return new n(e);
    case $p:
      return vp(e);
    case Ap:
      return new n();
    case Ep:
      return mp(e);
  }
}
var Dp = Vp, Lp = nt, kn = Object.create, Up = /* @__PURE__ */ function() {
  function e() {
  }
  return function(t) {
    if (!Lp(t))
      return {};
    if (kn)
      return kn(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), Hp = Up, Zp = Hp, qp = Ea, Bp = Nr;
function Gp(e) {
  return typeof e.constructor == "function" && !Bp(e) ? Zp(qp(e)) : {};
}
var zp = Gp, Fp = zt, Kp = Ve, Wp = "[object Map]";
function Qp(e) {
  return Kp(e) && Fp(e) == Wp;
}
var Jp = Qp, Yp = Jp, Xp = Cr, jn = Ir, On = jn && jn.isMap, eh = On ? Xp(On) : Yp, th = eh, rh = zt, nh = Ve, ah = "[object Set]";
function sh(e) {
  return nh(e) && rh(e) == ah;
}
var ih = sh, oh = ih, ch = Cr, Cn = Ir, In = Cn && Cn.isSet, uh = In ? ch(In) : oh, lh = uh, dh = ha, fh = Ou, ph = ma, hh = $d, vh = Ld, mh = Ud, yh = Zd, gh = ef, _h = hf, bh = Ra, Th = xf, $h = zt, Ah = Yf, Sh = Dp, Eh = zp, xh = Gt, wh = Or, Rh = th, kh = nt, jh = lh, Oh = Mr, Ch = Pr, Ih = 1, Nh = 2, Mh = 4, Oa = "[object Arguments]", Ph = "[object Array]", Vh = "[object Boolean]", Dh = "[object Date]", Lh = "[object Error]", Ca = "[object Function]", Uh = "[object GeneratorFunction]", Hh = "[object Map]", Zh = "[object Number]", Ia = "[object Object]", qh = "[object RegExp]", Bh = "[object Set]", Gh = "[object String]", zh = "[object Symbol]", Fh = "[object WeakMap]", Kh = "[object ArrayBuffer]", Wh = "[object DataView]", Qh = "[object Float32Array]", Jh = "[object Float64Array]", Yh = "[object Int8Array]", Xh = "[object Int16Array]", ev = "[object Int32Array]", tv = "[object Uint8Array]", rv = "[object Uint8ClampedArray]", nv = "[object Uint16Array]", av = "[object Uint32Array]", E = {};
E[Oa] = E[Ph] = E[Kh] = E[Wh] = E[Vh] = E[Dh] = E[Qh] = E[Jh] = E[Yh] = E[Xh] = E[ev] = E[Hh] = E[Zh] = E[Ia] = E[qh] = E[Bh] = E[Gh] = E[zh] = E[tv] = E[rv] = E[nv] = E[av] = !0;
E[Lh] = E[Ca] = E[Fh] = !1;
function $t(e, t, r, n, s, i) {
  var o, c = t & Ih, u = t & Nh, l = t & Mh;
  if (r && (o = s ? r(e, n, s, i) : r(e)), o !== void 0)
    return o;
  if (!kh(e))
    return e;
  var f = xh(e);
  if (f) {
    if (o = Ah(e), !c)
      return yh(e, o);
  } else {
    var _ = $h(e), S = _ == Ca || _ == Uh;
    if (wh(e))
      return mh(e, c);
    if (_ == Ia || _ == Oa || S && !s) {
      if (o = u || S ? {} : Eh(e), !c)
        return u ? _h(e, vh(o, e)) : gh(e, hh(o, e));
    } else {
      if (!E[_])
        return s ? e : {};
      o = Sh(e, _, c);
    }
  }
  i || (i = new dh());
  var I = i.get(e);
  if (I)
    return I;
  i.set(e, o), jh(e) ? e.forEach(function(O) {
    o.add($t(O, t, r, O, e, i));
  }) : Rh(e) && e.forEach(function(O, M) {
    o.set(M, $t(O, t, r, M, e, i));
  });
  var V = l ? u ? Th : bh : u ? Ch : Oh, D = f ? void 0 : V(e);
  return fh(D || e, function(O, M) {
    D && (M = O, O = e[M]), ph(o, M, $t(O, t, r, M, e, i));
  }), o;
}
var sv = $t, iv = sv, ov = 1, cv = 4;
function uv(e) {
  return iv(e, ov | cv);
}
var lv = uv;
const dv = /* @__PURE__ */ ca(lv);
var $;
(function(e) {
  e.assertEqual = (s) => s;
  function t(s) {
  }
  e.assertIs = t;
  function r(s) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (s) => {
    const i = {};
    for (const o of s)
      i[o] = o;
    return i;
  }, e.getValidEnumValues = (s) => {
    const i = e.objectKeys(s).filter((c) => typeof s[s[c]] != "number"), o = {};
    for (const c of i)
      o[c] = s[c];
    return e.objectValues(o);
  }, e.objectValues = (s) => e.objectKeys(s).map(function(i) {
    return s[i];
  }), e.objectKeys = typeof Object.keys == "function" ? (s) => Object.keys(s) : (s) => {
    const i = [];
    for (const o in s)
      Object.prototype.hasOwnProperty.call(s, o) && i.push(o);
    return i;
  }, e.find = (s, i) => {
    for (const o of s)
      if (i(o))
        return o;
  }, e.isInteger = typeof Number.isInteger == "function" ? (s) => Number.isInteger(s) : (s) => typeof s == "number" && isFinite(s) && Math.floor(s) === s;
  function n(s, i = " | ") {
    return s.map((o) => typeof o == "string" ? `'${o}'` : o).join(i);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (s, i) => typeof i == "bigint" ? i.toString() : i;
})($ || ($ = {}));
var br;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(br || (br = {}));
const h = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), J = (e) => {
  switch (typeof e) {
    case "undefined":
      return h.undefined;
    case "string":
      return h.string;
    case "number":
      return isNaN(e) ? h.nan : h.number;
    case "boolean":
      return h.boolean;
    case "function":
      return h.function;
    case "bigint":
      return h.bigint;
    case "symbol":
      return h.symbol;
    case "object":
      return Array.isArray(e) ? h.array : e === null ? h.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? h.promise : typeof Map < "u" && e instanceof Map ? h.map : typeof Set < "u" && e instanceof Set ? h.set : typeof Date < "u" && e instanceof Date ? h.date : h.object;
    default:
      return h.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), fv = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class L extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(i) {
      return i.message;
    }, n = { _errors: [] }, s = (i) => {
      for (const o of i.issues)
        if (o.code === "invalid_union")
          o.unionErrors.map(s);
        else if (o.code === "invalid_return_type")
          s(o.returnTypeError);
        else if (o.code === "invalid_arguments")
          s(o.argumentsError);
        else if (o.path.length === 0)
          n._errors.push(r(o));
        else {
          let c = n, u = 0;
          for (; u < o.path.length; ) {
            const l = o.path[u];
            u === o.path.length - 1 ? (c[l] = c[l] || { _errors: [] }, c[l]._errors.push(r(o))) : c[l] = c[l] || { _errors: [] }, c = c[l], u++;
          }
        }
    };
    return s(this), n;
  }
  static assert(t) {
    if (!(t instanceof L))
      throw new Error(`Not a ZodError: ${t}`);
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const s of this.issues)
      s.path.length > 0 ? (r[s.path[0]] = r[s.path[0]] || [], r[s.path[0]].push(t(s))) : n.push(t(s));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
L.create = (e) => new L(e);
const xe = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === h.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let Na = xe;
function pv(e) {
  Na = e;
}
function xt() {
  return Na;
}
const wt = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: s } = e, i = [...r, ...s.path || []], o = {
    ...s,
    path: i
  };
  if (s.message !== void 0)
    return {
      ...s,
      path: i,
      message: s.message
    };
  let c = "";
  const u = n.filter((l) => !!l).slice().reverse();
  for (const l of u)
    c = l(o, { data: t, defaultError: c }).message;
  return {
    ...s,
    path: i,
    message: c
  };
}, hv = [];
function p(e, t) {
  const r = xt(), n = wt({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      r,
      r === xe ? void 0 : xe
      // then global default map
    ].filter((s) => !!s)
  });
  e.common.issues.push(n);
}
class N {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const s of r) {
      if (s.status === "aborted")
        return y;
      s.status === "dirty" && t.dirty(), n.push(s.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const s of r) {
      const i = await s.key, o = await s.value;
      n.push({
        key: i,
        value: o
      });
    }
    return N.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const s of r) {
      const { key: i, value: o } = s;
      if (i.status === "aborted" || o.status === "aborted")
        return y;
      i.status === "dirty" && t.dirty(), o.status === "dirty" && t.dirty(), i.value !== "__proto__" && (typeof o.value < "u" || s.alwaysSet) && (n[i.value] = o.value);
    }
    return { status: t.value, value: n };
  }
}
const y = Object.freeze({
  status: "aborted"
}), Se = (e) => ({ status: "dirty", value: e }), P = (e) => ({ status: "valid", value: e }), Tr = (e) => e.status === "aborted", $r = (e) => e.status === "dirty", Ze = (e) => e.status === "valid", qe = (e) => typeof Promise < "u" && e instanceof Promise;
function Rt(e, t, r, n) {
  if (typeof t == "function" ? e !== t || !n : !t.has(e)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return t.get(e);
}
function Ma(e, t, r, n, s) {
  if (typeof t == "function" ? e !== t || !s : !t.has(e)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return t.set(e, r), r;
}
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
var Ue, He;
class B {
  constructor(t, r, n, s) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = s;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const Nn = (e, t) => {
  if (Ze(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new L(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function b(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: s } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: s } : { errorMap: (o, c) => {
    var u, l;
    const { message: f } = e;
    return o.code === "invalid_enum_value" ? { message: f ?? c.defaultError } : typeof c.data > "u" ? { message: (u = f ?? n) !== null && u !== void 0 ? u : c.defaultError } : o.code !== "invalid_type" ? { message: c.defaultError } : { message: (l = f ?? r) !== null && l !== void 0 ? l : c.defaultError };
  }, description: s };
}
class T {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return J(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: J(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new N(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: J(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (qe(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const s = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: J(t)
    }, i = this._parseSync({ data: t, path: s.path, parent: s });
    return Nn(s, i);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: J(t)
    }, s = this._parse({ data: t, path: n.path, parent: n }), i = await (qe(s) ? s : Promise.resolve(s));
    return Nn(n, i);
  }
  refine(t, r) {
    const n = (s) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(s) : r;
    return this._refinement((s, i) => {
      const o = t(s), c = () => i.addIssue({
        code: d.custom,
        ...n(s)
      });
      return typeof Promise < "u" && o instanceof Promise ? o.then((u) => u ? !0 : (c(), !1)) : o ? !0 : (c(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, s) => t(n) ? !0 : (s.addIssue(typeof r == "function" ? r(n, s) : r), !1));
  }
  _refinement(t) {
    return new Z({
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return q.create(this, this._def);
  }
  nullable() {
    return re.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return H.create(this, this._def);
  }
  promise() {
    return Re.create(this, this._def);
  }
  or(t) {
    return Fe.create([this, t], this._def);
  }
  and(t) {
    return Ke.create(this, t, this._def);
  }
  transform(t) {
    return new Z({
      ...b(this._def),
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Xe({
      ...b(this._def),
      innerType: this,
      defaultValue: r,
      typeName: m.ZodDefault
    });
  }
  brand() {
    return new Lr({
      typeName: m.ZodBranded,
      type: this,
      ...b(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new et({
      ...b(this._def),
      innerType: this,
      catchValue: r,
      typeName: m.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return at.create(this, t);
  }
  readonly() {
    return tt.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const vv = /^c[^\s-]{8,}$/i, mv = /^[0-9a-z]+$/, yv = /^[0-9A-HJKMNP-TV-Z]{26}$/, gv = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, _v = /^[a-z0-9_-]{21}$/i, bv = /^[-+]?P(?!$)(?:(?:[-+]?\d+Y)|(?:[-+]?\d+[.,]\d+Y$))?(?:(?:[-+]?\d+M)|(?:[-+]?\d+[.,]\d+M$))?(?:(?:[-+]?\d+W)|(?:[-+]?\d+[.,]\d+W$))?(?:(?:[-+]?\d+D)|(?:[-+]?\d+[.,]\d+D$))?(?:T(?=[\d+-])(?:(?:[-+]?\d+H)|(?:[-+]?\d+[.,]\d+H$))?(?:(?:[-+]?\d+M)|(?:[-+]?\d+[.,]\d+M$))?(?:[-+]?\d+(?:[.,]\d+)?S)?)??$/, Tv = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, $v = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let lr;
const Av = /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/, Sv = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, Ev = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/, Pa = "((\\d\\d[2468][048]|\\d\\d[13579][26]|\\d\\d0[48]|[02468][048]00|[13579][26]00)-02-29|\\d{4}-((0[13578]|1[02])-(0[1-9]|[12]\\d|3[01])|(0[469]|11)-(0[1-9]|[12]\\d|30)|(02)-(0[1-9]|1\\d|2[0-8])))", xv = new RegExp(`^${Pa}$`);
function Va(e) {
  let t = "([01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d";
  return e.precision ? t = `${t}\\.\\d{${e.precision}}` : e.precision == null && (t = `${t}(\\.\\d+)?`), t;
}
function wv(e) {
  return new RegExp(`^${Va(e)}$`);
}
function Da(e) {
  let t = `${Pa}T${Va(e)}`;
  const r = [];
  return r.push(e.local ? "Z?" : "Z"), e.offset && r.push("([+-]\\d{2}:?\\d{2})"), t = `${t}(${r.join("|")})`, new RegExp(`^${t}$`);
}
function Rv(e, t) {
  return !!((t === "v4" || !t) && Av.test(e) || (t === "v6" || !t) && Sv.test(e));
}
class U extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== h.string) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: h.string,
        received: i.parsedType
      }), y;
    }
    const n = new N();
    let s;
    for (const i of this._def.checks)
      if (i.kind === "min")
        t.data.length < i.value && (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.too_small,
          minimum: i.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: i.message
        }), n.dirty());
      else if (i.kind === "max")
        t.data.length > i.value && (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.too_big,
          maximum: i.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: i.message
        }), n.dirty());
      else if (i.kind === "length") {
        const o = t.data.length > i.value, c = t.data.length < i.value;
        (o || c) && (s = this._getOrReturnCtx(t, s), o ? p(s, {
          code: d.too_big,
          maximum: i.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: i.message
        }) : c && p(s, {
          code: d.too_small,
          minimum: i.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: i.message
        }), n.dirty());
      } else if (i.kind === "email")
        Tv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "email",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "emoji")
        lr || (lr = new RegExp($v, "u")), lr.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "emoji",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "uuid")
        gv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "uuid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "nanoid")
        _v.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "nanoid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "cuid")
        vv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "cuid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "cuid2")
        mv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "cuid2",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "ulid")
        yv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "ulid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "url")
        try {
          new URL(t.data);
        } catch {
          s = this._getOrReturnCtx(t, s), p(s, {
            validation: "url",
            code: d.invalid_string,
            message: i.message
          }), n.dirty();
        }
      else i.kind === "regex" ? (i.regex.lastIndex = 0, i.regex.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        validation: "regex",
        code: d.invalid_string,
        message: i.message
      }), n.dirty())) : i.kind === "trim" ? t.data = t.data.trim() : i.kind === "includes" ? t.data.includes(i.value, i.position) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: { includes: i.value, position: i.position },
        message: i.message
      }), n.dirty()) : i.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : i.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : i.kind === "startsWith" ? t.data.startsWith(i.value) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: { startsWith: i.value },
        message: i.message
      }), n.dirty()) : i.kind === "endsWith" ? t.data.endsWith(i.value) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: { endsWith: i.value },
        message: i.message
      }), n.dirty()) : i.kind === "datetime" ? Da(i).test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: "datetime",
        message: i.message
      }), n.dirty()) : i.kind === "date" ? xv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: "date",
        message: i.message
      }), n.dirty()) : i.kind === "time" ? wv(i).test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: "time",
        message: i.message
      }), n.dirty()) : i.kind === "duration" ? bv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        validation: "duration",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : i.kind === "ip" ? Rv(t.data, i.version) || (s = this._getOrReturnCtx(t, s), p(s, {
        validation: "ip",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : i.kind === "base64" ? Ev.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        validation: "base64",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : $.assertNever(i);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((s) => t.test(s), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new U({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  nanoid(t) {
    return this._addCheck({ kind: "nanoid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  base64(t) {
    return this._addCheck({ kind: "base64", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r, n;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      local: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      local: (n = t == null ? void 0 : t.local) !== null && n !== void 0 ? n : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  date(t) {
    return this._addCheck({ kind: "date", message: t });
  }
  time(t) {
    return typeof t == "string" ? this._addCheck({
      kind: "time",
      precision: null,
      message: t
    }) : this._addCheck({
      kind: "time",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  duration(t) {
    return this._addCheck({ kind: "duration", ...v.errToObj(t) });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new U({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new U({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new U({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isDate() {
    return !!this._def.checks.find((t) => t.kind === "date");
  }
  get isTime() {
    return !!this._def.checks.find((t) => t.kind === "time");
  }
  get isDuration() {
    return !!this._def.checks.find((t) => t.kind === "duration");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isNANOID() {
    return !!this._def.checks.find((t) => t.kind === "nanoid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get isBase64() {
    return !!this._def.checks.find((t) => t.kind === "base64");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
U.create = (e) => {
  var t;
  return new U({
    checks: [],
    typeName: m.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ...b(e)
  });
};
function kv(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, s = r > n ? r : n, i = parseInt(e.toFixed(s).replace(".", "")), o = parseInt(t.toFixed(s).replace(".", ""));
  return i % o / Math.pow(10, s);
}
class X extends T {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== h.number) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: h.number,
        received: i.parsedType
      }), y;
    }
    let n;
    const s = new N();
    for (const i of this._def.checks)
      i.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: i.message
      }), s.dirty()) : i.kind === "min" ? (i.inclusive ? t.data < i.value : t.data <= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        minimum: i.value,
        type: "number",
        inclusive: i.inclusive,
        exact: !1,
        message: i.message
      }), s.dirty()) : i.kind === "max" ? (i.inclusive ? t.data > i.value : t.data >= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        maximum: i.value,
        type: "number",
        inclusive: i.inclusive,
        exact: !1,
        message: i.message
      }), s.dirty()) : i.kind === "multipleOf" ? kv(t.data, i.value) !== 0 && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: i.value,
        message: i.message
      }), s.dirty()) : i.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_finite,
        message: i.message
      }), s.dirty()) : $.assertNever(i);
    return { status: s.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, s) {
    return new X({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(s)
        }
      ]
    });
  }
  _addCheck(t) {
    return new X({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
X.create = (e) => new X({
  checks: [],
  typeName: m.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ...b(e)
});
class ee extends T {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== h.bigint) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: h.bigint,
        received: i.parsedType
      }), y;
    }
    let n;
    const s = new N();
    for (const i of this._def.checks)
      i.kind === "min" ? (i.inclusive ? t.data < i.value : t.data <= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        type: "bigint",
        minimum: i.value,
        inclusive: i.inclusive,
        message: i.message
      }), s.dirty()) : i.kind === "max" ? (i.inclusive ? t.data > i.value : t.data >= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        type: "bigint",
        maximum: i.value,
        inclusive: i.inclusive,
        message: i.message
      }), s.dirty()) : i.kind === "multipleOf" ? t.data % i.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: i.value,
        message: i.message
      }), s.dirty()) : $.assertNever(i);
    return { status: s.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, s) {
    return new ee({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(s)
        }
      ]
    });
  }
  _addCheck(t) {
    return new ee({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
ee.create = (e) => {
  var t;
  return new ee({
    checks: [],
    typeName: m.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ...b(e)
  });
};
class Be extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== h.boolean) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.boolean,
        received: n.parsedType
      }), y;
    }
    return P(t.data);
  }
}
Be.create = (e) => new Be({
  typeName: m.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ...b(e)
});
class ue extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== h.date) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: h.date,
        received: i.parsedType
      }), y;
    }
    if (isNaN(t.data.getTime())) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_date
      }), y;
    }
    const n = new N();
    let s;
    for (const i of this._def.checks)
      i.kind === "min" ? t.data.getTime() < i.value && (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.too_small,
        message: i.message,
        inclusive: !0,
        exact: !1,
        minimum: i.value,
        type: "date"
      }), n.dirty()) : i.kind === "max" ? t.data.getTime() > i.value && (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.too_big,
        message: i.message,
        inclusive: !0,
        exact: !1,
        maximum: i.value,
        type: "date"
      }), n.dirty()) : $.assertNever(i);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new ue({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
ue.create = (e) => new ue({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: m.ZodDate,
  ...b(e)
});
class kt extends T {
  _parse(t) {
    if (this._getType(t) !== h.symbol) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.symbol,
        received: n.parsedType
      }), y;
    }
    return P(t.data);
  }
}
kt.create = (e) => new kt({
  typeName: m.ZodSymbol,
  ...b(e)
});
class Ge extends T {
  _parse(t) {
    if (this._getType(t) !== h.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.undefined,
        received: n.parsedType
      }), y;
    }
    return P(t.data);
  }
}
Ge.create = (e) => new Ge({
  typeName: m.ZodUndefined,
  ...b(e)
});
class ze extends T {
  _parse(t) {
    if (this._getType(t) !== h.null) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.null,
        received: n.parsedType
      }), y;
    }
    return P(t.data);
  }
}
ze.create = (e) => new ze({
  typeName: m.ZodNull,
  ...b(e)
});
class we extends T {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return P(t.data);
  }
}
we.create = (e) => new we({
  typeName: m.ZodAny,
  ...b(e)
});
class ce extends T {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return P(t.data);
  }
}
ce.create = (e) => new ce({
  typeName: m.ZodUnknown,
  ...b(e)
});
class W extends T {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return p(r, {
      code: d.invalid_type,
      expected: h.never,
      received: r.parsedType
    }), y;
  }
}
W.create = (e) => new W({
  typeName: m.ZodNever,
  ...b(e)
});
class jt extends T {
  _parse(t) {
    if (this._getType(t) !== h.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.void,
        received: n.parsedType
      }), y;
    }
    return P(t.data);
  }
}
jt.create = (e) => new jt({
  typeName: m.ZodVoid,
  ...b(e)
});
class H extends T {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), s = this._def;
    if (r.parsedType !== h.array)
      return p(r, {
        code: d.invalid_type,
        expected: h.array,
        received: r.parsedType
      }), y;
    if (s.exactLength !== null) {
      const o = r.data.length > s.exactLength.value, c = r.data.length < s.exactLength.value;
      (o || c) && (p(r, {
        code: o ? d.too_big : d.too_small,
        minimum: c ? s.exactLength.value : void 0,
        maximum: o ? s.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: s.exactLength.message
      }), n.dirty());
    }
    if (s.minLength !== null && r.data.length < s.minLength.value && (p(r, {
      code: d.too_small,
      minimum: s.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.minLength.message
    }), n.dirty()), s.maxLength !== null && r.data.length > s.maxLength.value && (p(r, {
      code: d.too_big,
      maximum: s.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((o, c) => s.type._parseAsync(new B(r, o, r.path, c)))).then((o) => N.mergeArray(n, o));
    const i = [...r.data].map((o, c) => s.type._parseSync(new B(r, o, r.path, c)));
    return N.mergeArray(n, i);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new H({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new H({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new H({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
H.create = (e, t) => new H({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: m.ZodArray,
  ...b(t)
});
function Ae(e) {
  if (e instanceof w) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = q.create(Ae(n));
    }
    return new w({
      ...e._def,
      shape: () => t
    });
  } else return e instanceof H ? new H({
    ...e._def,
    type: Ae(e.element)
  }) : e instanceof q ? q.create(Ae(e.unwrap())) : e instanceof re ? re.create(Ae(e.unwrap())) : e instanceof G ? G.create(e.items.map((t) => Ae(t))) : e;
}
class w extends T {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== h.object) {
      const l = this._getOrReturnCtx(t);
      return p(l, {
        code: d.invalid_type,
        expected: h.object,
        received: l.parsedType
      }), y;
    }
    const { status: n, ctx: s } = this._processInputParams(t), { shape: i, keys: o } = this._getCached(), c = [];
    if (!(this._def.catchall instanceof W && this._def.unknownKeys === "strip"))
      for (const l in s.data)
        o.includes(l) || c.push(l);
    const u = [];
    for (const l of o) {
      const f = i[l], _ = s.data[l];
      u.push({
        key: { status: "valid", value: l },
        value: f._parse(new B(s, _, s.path, l)),
        alwaysSet: l in s.data
      });
    }
    if (this._def.catchall instanceof W) {
      const l = this._def.unknownKeys;
      if (l === "passthrough")
        for (const f of c)
          u.push({
            key: { status: "valid", value: f },
            value: { status: "valid", value: s.data[f] }
          });
      else if (l === "strict")
        c.length > 0 && (p(s, {
          code: d.unrecognized_keys,
          keys: c
        }), n.dirty());
      else if (l !== "strip") throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const l = this._def.catchall;
      for (const f of c) {
        const _ = s.data[f];
        u.push({
          key: { status: "valid", value: f },
          value: l._parse(
            new B(s, _, s.path, f)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: f in s.data
        });
      }
    }
    return s.common.async ? Promise.resolve().then(async () => {
      const l = [];
      for (const f of u) {
        const _ = await f.key, S = await f.value;
        l.push({
          key: _,
          value: S,
          alwaysSet: f.alwaysSet
        });
      }
      return l;
    }).then((l) => N.mergeObjectSync(n, l)) : N.mergeObjectSync(n, u);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new w({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var s, i, o, c;
          const u = (o = (i = (s = this._def).errorMap) === null || i === void 0 ? void 0 : i.call(s, r, n).message) !== null && o !== void 0 ? o : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (c = v.errToObj(t).message) !== null && c !== void 0 ? c : u
          } : {
            message: u
          };
        }
      } : {}
    });
  }
  strip() {
    return new w({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new w({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new w({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new w({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: m.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new w({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new w({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new w({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return Ae(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const s = this.shape[n];
      t && !t[n] ? r[n] = s : r[n] = s.optional();
    }), new w({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let i = this.shape[n];
        for (; i instanceof q; )
          i = i._def.innerType;
        r[n] = i;
      }
    }), new w({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return La($.objectKeys(this.shape));
  }
}
w.create = (e, t) => new w({
  shape: () => e,
  unknownKeys: "strip",
  catchall: W.create(),
  typeName: m.ZodObject,
  ...b(t)
});
w.strictCreate = (e, t) => new w({
  shape: () => e,
  unknownKeys: "strict",
  catchall: W.create(),
  typeName: m.ZodObject,
  ...b(t)
});
w.lazycreate = (e, t) => new w({
  shape: e,
  unknownKeys: "strip",
  catchall: W.create(),
  typeName: m.ZodObject,
  ...b(t)
});
class Fe extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function s(i) {
      for (const c of i)
        if (c.result.status === "valid")
          return c.result;
      for (const c of i)
        if (c.result.status === "dirty")
          return r.common.issues.push(...c.ctx.common.issues), c.result;
      const o = i.map((c) => new L(c.ctx.common.issues));
      return p(r, {
        code: d.invalid_union,
        unionErrors: o
      }), y;
    }
    if (r.common.async)
      return Promise.all(n.map(async (i) => {
        const o = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await i._parseAsync({
            data: r.data,
            path: r.path,
            parent: o
          }),
          ctx: o
        };
      })).then(s);
    {
      let i;
      const o = [];
      for (const u of n) {
        const l = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, f = u._parseSync({
          data: r.data,
          path: r.path,
          parent: l
        });
        if (f.status === "valid")
          return f;
        f.status === "dirty" && !i && (i = { result: f, ctx: l }), l.common.issues.length && o.push(l.common.issues);
      }
      if (i)
        return r.common.issues.push(...i.ctx.common.issues), i.result;
      const c = o.map((u) => new L(u));
      return p(r, {
        code: d.invalid_union,
        unionErrors: c
      }), y;
    }
  }
  get options() {
    return this._def.options;
  }
}
Fe.create = (e, t) => new Fe({
  options: e,
  typeName: m.ZodUnion,
  ...b(t)
});
const K = (e) => e instanceof Qe ? K(e.schema) : e instanceof Z ? K(e.innerType()) : e instanceof Je ? [e.value] : e instanceof te ? e.options : e instanceof Ye ? $.objectValues(e.enum) : e instanceof Xe ? K(e._def.innerType) : e instanceof Ge ? [void 0] : e instanceof ze ? [null] : e instanceof q ? [void 0, ...K(e.unwrap())] : e instanceof re ? [null, ...K(e.unwrap())] : e instanceof Lr || e instanceof tt ? K(e.unwrap()) : e instanceof et ? K(e._def.innerType) : [];
class Ft extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== h.object)
      return p(r, {
        code: d.invalid_type,
        expected: h.object,
        received: r.parsedType
      }), y;
    const n = this.discriminator, s = r.data[n], i = this.optionsMap.get(s);
    return i ? r.common.async ? i._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : i._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (p(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), y);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const s = /* @__PURE__ */ new Map();
    for (const i of r) {
      const o = K(i.shape[t]);
      if (!o.length)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const c of o) {
        if (s.has(c))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(c)}`);
        s.set(c, i);
      }
    }
    return new Ft({
      typeName: m.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: s,
      ...b(n)
    });
  }
}
function Ar(e, t) {
  const r = J(e), n = J(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === h.object && n === h.object) {
    const s = $.objectKeys(t), i = $.objectKeys(e).filter((c) => s.indexOf(c) !== -1), o = { ...e, ...t };
    for (const c of i) {
      const u = Ar(e[c], t[c]);
      if (!u.valid)
        return { valid: !1 };
      o[c] = u.data;
    }
    return { valid: !0, data: o };
  } else if (r === h.array && n === h.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const s = [];
    for (let i = 0; i < e.length; i++) {
      const o = e[i], c = t[i], u = Ar(o, c);
      if (!u.valid)
        return { valid: !1 };
      s.push(u.data);
    }
    return { valid: !0, data: s };
  } else return r === h.date && n === h.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class Ke extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), s = (i, o) => {
      if (Tr(i) || Tr(o))
        return y;
      const c = Ar(i.value, o.value);
      return c.valid ? (($r(i) || $r(o)) && r.dirty(), { status: r.value, value: c.data }) : (p(n, {
        code: d.invalid_intersection_types
      }), y);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([i, o]) => s(i, o)) : s(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
Ke.create = (e, t, r) => new Ke({
  left: e,
  right: t,
  typeName: m.ZodIntersection,
  ...b(r)
});
class G extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== h.array)
      return p(n, {
        code: d.invalid_type,
        expected: h.array,
        received: n.parsedType
      }), y;
    if (n.data.length < this._def.items.length)
      return p(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), y;
    !this._def.rest && n.data.length > this._def.items.length && (p(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const i = [...n.data].map((o, c) => {
      const u = this._def.items[c] || this._def.rest;
      return u ? u._parse(new B(n, o, n.path, c)) : null;
    }).filter((o) => !!o);
    return n.common.async ? Promise.all(i).then((o) => N.mergeArray(r, o)) : N.mergeArray(r, i);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new G({
      ...this._def,
      rest: t
    });
  }
}
G.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new G({
    items: e,
    typeName: m.ZodTuple,
    rest: null,
    ...b(t)
  });
};
class We extends T {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== h.object)
      return p(n, {
        code: d.invalid_type,
        expected: h.object,
        received: n.parsedType
      }), y;
    const s = [], i = this._def.keyType, o = this._def.valueType;
    for (const c in n.data)
      s.push({
        key: i._parse(new B(n, c, n.path, c)),
        value: o._parse(new B(n, n.data[c], n.path, c)),
        alwaysSet: c in n.data
      });
    return n.common.async ? N.mergeObjectAsync(r, s) : N.mergeObjectSync(r, s);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof T ? new We({
      keyType: t,
      valueType: r,
      typeName: m.ZodRecord,
      ...b(n)
    }) : new We({
      keyType: U.create(),
      valueType: t,
      typeName: m.ZodRecord,
      ...b(r)
    });
  }
}
class Ot extends T {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== h.map)
      return p(n, {
        code: d.invalid_type,
        expected: h.map,
        received: n.parsedType
      }), y;
    const s = this._def.keyType, i = this._def.valueType, o = [...n.data.entries()].map(([c, u], l) => ({
      key: s._parse(new B(n, c, n.path, [l, "key"])),
      value: i._parse(new B(n, u, n.path, [l, "value"]))
    }));
    if (n.common.async) {
      const c = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const u of o) {
          const l = await u.key, f = await u.value;
          if (l.status === "aborted" || f.status === "aborted")
            return y;
          (l.status === "dirty" || f.status === "dirty") && r.dirty(), c.set(l.value, f.value);
        }
        return { status: r.value, value: c };
      });
    } else {
      const c = /* @__PURE__ */ new Map();
      for (const u of o) {
        const l = u.key, f = u.value;
        if (l.status === "aborted" || f.status === "aborted")
          return y;
        (l.status === "dirty" || f.status === "dirty") && r.dirty(), c.set(l.value, f.value);
      }
      return { status: r.value, value: c };
    }
  }
}
Ot.create = (e, t, r) => new Ot({
  valueType: t,
  keyType: e,
  typeName: m.ZodMap,
  ...b(r)
});
class le extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== h.set)
      return p(n, {
        code: d.invalid_type,
        expected: h.set,
        received: n.parsedType
      }), y;
    const s = this._def;
    s.minSize !== null && n.data.size < s.minSize.value && (p(n, {
      code: d.too_small,
      minimum: s.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.minSize.message
    }), r.dirty()), s.maxSize !== null && n.data.size > s.maxSize.value && (p(n, {
      code: d.too_big,
      maximum: s.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.maxSize.message
    }), r.dirty());
    const i = this._def.valueType;
    function o(u) {
      const l = /* @__PURE__ */ new Set();
      for (const f of u) {
        if (f.status === "aborted")
          return y;
        f.status === "dirty" && r.dirty(), l.add(f.value);
      }
      return { status: r.value, value: l };
    }
    const c = [...n.data.values()].map((u, l) => i._parse(new B(n, u, n.path, l)));
    return n.common.async ? Promise.all(c).then((u) => o(u)) : o(c);
  }
  min(t, r) {
    return new le({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new le({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
le.create = (e, t) => new le({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: m.ZodSet,
  ...b(t)
});
class Ee extends T {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== h.function)
      return p(r, {
        code: d.invalid_type,
        expected: h.function,
        received: r.parsedType
      }), y;
    function n(c, u) {
      return wt({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          xt(),
          xe
        ].filter((l) => !!l),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: u
        }
      });
    }
    function s(c, u) {
      return wt({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          xt(),
          xe
        ].filter((l) => !!l),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: u
        }
      });
    }
    const i = { errorMap: r.common.contextualErrorMap }, o = r.data;
    if (this._def.returns instanceof Re) {
      const c = this;
      return P(async function(...u) {
        const l = new L([]), f = await c._def.args.parseAsync(u, i).catch((I) => {
          throw l.addIssue(n(u, I)), l;
        }), _ = await Reflect.apply(o, this, f);
        return await c._def.returns._def.type.parseAsync(_, i).catch((I) => {
          throw l.addIssue(s(_, I)), l;
        });
      });
    } else {
      const c = this;
      return P(function(...u) {
        const l = c._def.args.safeParse(u, i);
        if (!l.success)
          throw new L([n(u, l.error)]);
        const f = Reflect.apply(o, this, l.data), _ = c._def.returns.safeParse(f, i);
        if (!_.success)
          throw new L([s(f, _.error)]);
        return _.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new Ee({
      ...this._def,
      args: G.create(t).rest(ce.create())
    });
  }
  returns(t) {
    return new Ee({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new Ee({
      args: t || G.create([]).rest(ce.create()),
      returns: r || ce.create(),
      typeName: m.ZodFunction,
      ...b(n)
    });
  }
}
class Qe extends T {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
Qe.create = (e, t) => new Qe({
  getter: e,
  typeName: m.ZodLazy,
  ...b(t)
});
class Je extends T {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return p(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), y;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Je.create = (e, t) => new Je({
  value: e,
  typeName: m.ZodLiteral,
  ...b(t)
});
function La(e, t) {
  return new te({
    values: e,
    typeName: m.ZodEnum,
    ...b(t)
  });
}
class te extends T {
  constructor() {
    super(...arguments), Ue.set(this, void 0);
  }
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), y;
    }
    if (Rt(this, Ue) || Ma(this, Ue, new Set(this._def.values)), !Rt(this, Ue).has(t.data)) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), y;
    }
    return P(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t, r = this._def) {
    return te.create(t, {
      ...this._def,
      ...r
    });
  }
  exclude(t, r = this._def) {
    return te.create(this.options.filter((n) => !t.includes(n)), {
      ...this._def,
      ...r
    });
  }
}
Ue = /* @__PURE__ */ new WeakMap();
te.create = La;
class Ye extends T {
  constructor() {
    super(...arguments), He.set(this, void 0);
  }
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== h.string && n.parsedType !== h.number) {
      const s = $.objectValues(r);
      return p(n, {
        expected: $.joinValues(s),
        received: n.parsedType,
        code: d.invalid_type
      }), y;
    }
    if (Rt(this, He) || Ma(this, He, new Set($.getValidEnumValues(this._def.values))), !Rt(this, He).has(t.data)) {
      const s = $.objectValues(r);
      return p(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: s
      }), y;
    }
    return P(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
He = /* @__PURE__ */ new WeakMap();
Ye.create = (e, t) => new Ye({
  values: e,
  typeName: m.ZodNativeEnum,
  ...b(t)
});
class Re extends T {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== h.promise && r.common.async === !1)
      return p(r, {
        code: d.invalid_type,
        expected: h.promise,
        received: r.parsedType
      }), y;
    const n = r.parsedType === h.promise ? r.data : Promise.resolve(r.data);
    return P(n.then((s) => this._def.type.parseAsync(s, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
Re.create = (e, t) => new Re({
  type: e,
  typeName: m.ZodPromise,
  ...b(t)
});
class Z extends T {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === m.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), s = this._def.effect || null, i = {
      addIssue: (o) => {
        p(n, o), o.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (i.addIssue = i.addIssue.bind(i), s.type === "preprocess") {
      const o = s.transform(n.data, i);
      if (n.common.async)
        return Promise.resolve(o).then(async (c) => {
          if (r.value === "aborted")
            return y;
          const u = await this._def.schema._parseAsync({
            data: c,
            path: n.path,
            parent: n
          });
          return u.status === "aborted" ? y : u.status === "dirty" || r.value === "dirty" ? Se(u.value) : u;
        });
      {
        if (r.value === "aborted")
          return y;
        const c = this._def.schema._parseSync({
          data: o,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? y : c.status === "dirty" || r.value === "dirty" ? Se(c.value) : c;
      }
    }
    if (s.type === "refinement") {
      const o = (c) => {
        const u = s.refinement(c, i);
        if (n.common.async)
          return Promise.resolve(u);
        if (u instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return c;
      };
      if (n.common.async === !1) {
        const c = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? y : (c.status === "dirty" && r.dirty(), o(c.value), { status: r.value, value: c.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((c) => c.status === "aborted" ? y : (c.status === "dirty" && r.dirty(), o(c.value).then(() => ({ status: r.value, value: c.value }))));
    }
    if (s.type === "transform")
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!Ze(o))
          return o;
        const c = s.transform(o.value, i);
        if (c instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: c };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => Ze(o) ? Promise.resolve(s.transform(o.value, i)).then((c) => ({ status: r.value, value: c })) : o);
    $.assertNever(s);
  }
}
Z.create = (e, t, r) => new Z({
  schema: e,
  typeName: m.ZodEffects,
  effect: t,
  ...b(r)
});
Z.createWithPreprocess = (e, t, r) => new Z({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: m.ZodEffects,
  ...b(r)
});
class q extends T {
  _parse(t) {
    return this._getType(t) === h.undefined ? P(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
q.create = (e, t) => new q({
  innerType: e,
  typeName: m.ZodOptional,
  ...b(t)
});
class re extends T {
  _parse(t) {
    return this._getType(t) === h.null ? P(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
re.create = (e, t) => new re({
  innerType: e,
  typeName: m.ZodNullable,
  ...b(t)
});
class Xe extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === h.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Xe.create = (e, t) => new Xe({
  innerType: e,
  typeName: m.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ...b(t)
});
class et extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, s = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return qe(s) ? s.then((i) => ({
      status: "valid",
      value: i.status === "valid" ? i.value : this._def.catchValue({
        get error() {
          return new L(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new L(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
et.create = (e, t) => new et({
  innerType: e,
  typeName: m.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ...b(t)
});
class Ct extends T {
  _parse(t) {
    if (this._getType(t) !== h.nan) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.nan,
        received: n.parsedType
      }), y;
    }
    return { status: "valid", value: t.data };
  }
}
Ct.create = (e) => new Ct({
  typeName: m.ZodNaN,
  ...b(e)
});
const jv = Symbol("zod_brand");
class Lr extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class at extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const i = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return i.status === "aborted" ? y : i.status === "dirty" ? (r.dirty(), Se(i.value)) : this._def.out._parseAsync({
          data: i.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const s = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return s.status === "aborted" ? y : s.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: s.value
      }) : this._def.out._parseSync({
        data: s.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new at({
      in: t,
      out: r,
      typeName: m.ZodPipeline
    });
  }
}
class tt extends T {
  _parse(t) {
    const r = this._def.innerType._parse(t), n = (s) => (Ze(s) && (s.value = Object.freeze(s.value)), s);
    return qe(r) ? r.then((s) => n(s)) : n(r);
  }
  unwrap() {
    return this._def.innerType;
  }
}
tt.create = (e, t) => new tt({
  innerType: e,
  typeName: m.ZodReadonly,
  ...b(t)
});
function Ua(e, t = {}, r) {
  return e ? we.create().superRefine((n, s) => {
    var i, o;
    if (!e(n)) {
      const c = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, u = (o = (i = c.fatal) !== null && i !== void 0 ? i : r) !== null && o !== void 0 ? o : !0, l = typeof c == "string" ? { message: c } : c;
      s.addIssue({ code: "custom", ...l, fatal: u });
    }
  }) : we.create();
}
const Ov = {
  object: w.lazycreate
};
var m;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(m || (m = {}));
const Cv = (e, t = {
  message: `Input not instance of ${e.name}`
}) => Ua((r) => r instanceof e, t), Ha = U.create, Za = X.create, Iv = Ct.create, Nv = ee.create, qa = Be.create, Mv = ue.create, Pv = kt.create, Vv = Ge.create, Dv = ze.create, Lv = we.create, Uv = ce.create, Hv = W.create, Zv = jt.create, qv = H.create, Bv = w.create, Gv = w.strictCreate, zv = Fe.create, Fv = Ft.create, Kv = Ke.create, Wv = G.create, Qv = We.create, Jv = Ot.create, Yv = le.create, Xv = Ee.create, em = Qe.create, tm = Je.create, rm = te.create, nm = Ye.create, am = Re.create, Mn = Z.create, sm = q.create, im = re.create, om = Z.createWithPreprocess, cm = at.create, um = () => Ha().optional(), lm = () => Za().optional(), dm = () => qa().optional(), fm = {
  string: (e) => U.create({ ...e, coerce: !0 }),
  number: (e) => X.create({ ...e, coerce: !0 }),
  boolean: (e) => Be.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => ee.create({ ...e, coerce: !0 }),
  date: (e) => ue.create({ ...e, coerce: !0 })
}, pm = y;
var a = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: xe,
  setErrorMap: pv,
  getErrorMap: xt,
  makeIssue: wt,
  EMPTY_PATH: hv,
  addIssueToContext: p,
  ParseStatus: N,
  INVALID: y,
  DIRTY: Se,
  OK: P,
  isAborted: Tr,
  isDirty: $r,
  isValid: Ze,
  isAsync: qe,
  get util() {
    return $;
  },
  get objectUtil() {
    return br;
  },
  ZodParsedType: h,
  getParsedType: J,
  ZodType: T,
  datetimeRegex: Da,
  ZodString: U,
  ZodNumber: X,
  ZodBigInt: ee,
  ZodBoolean: Be,
  ZodDate: ue,
  ZodSymbol: kt,
  ZodUndefined: Ge,
  ZodNull: ze,
  ZodAny: we,
  ZodUnknown: ce,
  ZodNever: W,
  ZodVoid: jt,
  ZodArray: H,
  ZodObject: w,
  ZodUnion: Fe,
  ZodDiscriminatedUnion: Ft,
  ZodIntersection: Ke,
  ZodTuple: G,
  ZodRecord: We,
  ZodMap: Ot,
  ZodSet: le,
  ZodFunction: Ee,
  ZodLazy: Qe,
  ZodLiteral: Je,
  ZodEnum: te,
  ZodNativeEnum: Ye,
  ZodPromise: Re,
  ZodEffects: Z,
  ZodTransformer: Z,
  ZodOptional: q,
  ZodNullable: re,
  ZodDefault: Xe,
  ZodCatch: et,
  ZodNaN: Ct,
  BRAND: jv,
  ZodBranded: Lr,
  ZodPipeline: at,
  ZodReadonly: tt,
  custom: Ua,
  Schema: T,
  ZodSchema: T,
  late: Ov,
  get ZodFirstPartyTypeKind() {
    return m;
  },
  coerce: fm,
  any: Lv,
  array: qv,
  bigint: Nv,
  boolean: qa,
  date: Mv,
  discriminatedUnion: Fv,
  effect: Mn,
  enum: rm,
  function: Xv,
  instanceof: Cv,
  intersection: Kv,
  lazy: em,
  literal: tm,
  map: Jv,
  nan: Iv,
  nativeEnum: nm,
  never: Hv,
  null: Dv,
  nullable: im,
  number: Za,
  object: Bv,
  oboolean: dm,
  onumber: lm,
  optional: sm,
  ostring: um,
  pipeline: cm,
  preprocess: om,
  promise: am,
  record: Qv,
  set: Yv,
  strictObject: Gv,
  string: Ha,
  symbol: Pv,
  transformer: Mn,
  tuple: Wv,
  undefined: Vv,
  union: zv,
  unknown: Uv,
  void: Zv,
  NEVER: pm,
  ZodIssueCode: d,
  quotelessJson: fv,
  ZodError: L
}), A = (e) => e, hm = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return {
        type: "err",
        error: {
          type: "GIVEN_VER_VALIDATION_FAIL",
          version: t,
          versionDef: r,
          error: n.error
        }
      };
    let s = n.data;
    for (let i = t + 1; i <= this.latestVersion; i++) {
      const o = this.versionMap[i];
      if (!o)
        return {
          type: "err",
          error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: i }
        };
      if (o.initial)
        return {
          type: "err",
          error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: i }
        };
      s = o.up(s);
    }
    return { type: "ok", value: s };
  }
};
function st(e) {
  return new hm(e.versionMap, e.latestVersion, e.getVersion);
}
function It(e) {
  return a.custom((t) => e.is(t)).transform((t) => {
    const r = e.safeParse(t);
    if (r.type !== "ok")
      throw new Error(
        "Invalid entity definition. `entity.is` returned success, safeParse failed."
      );
    return r.value;
  });
}
var vm = "__lodash_hash_undefined__";
function mm(e) {
  return this.__data__.set(e, vm), this;
}
var ym = mm;
function gm(e) {
  return this.__data__.has(e);
}
var _m = gm, bm = pa, Tm = ym, $m = _m;
function Nt(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new bm(); ++t < r; )
    this.add(e[t]);
}
Nt.prototype.add = Nt.prototype.push = Tm;
Nt.prototype.has = $m;
var Am = Nt;
function Sm(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var Em = Sm;
function xm(e, t) {
  return e.has(t);
}
var wm = xm, Rm = Am, km = Em, jm = wm, Om = 1, Cm = 2;
function Im(e, t, r, n, s, i) {
  var o = r & Om, c = e.length, u = t.length;
  if (c != u && !(o && u > c))
    return !1;
  var l = i.get(e), f = i.get(t);
  if (l && f)
    return l == t && f == e;
  var _ = -1, S = !0, I = r & Cm ? new Rm() : void 0;
  for (i.set(e, t), i.set(t, e); ++_ < c; ) {
    var V = e[_], D = t[_];
    if (n)
      var O = o ? n(D, V, _, t, e, i) : n(V, D, _, e, t, i);
    if (O !== void 0) {
      if (O)
        continue;
      S = !1;
      break;
    }
    if (I) {
      if (!km(t, function(M, ie) {
        if (!jm(I, ie) && (V === M || s(V, M, r, n, i)))
          return I.push(ie);
      })) {
        S = !1;
        break;
      }
    } else if (!(V === D || s(V, D, r, n, i))) {
      S = !1;
      break;
    }
  }
  return i.delete(e), i.delete(t), S;
}
var Ba = Im;
function Nm(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, s) {
    r[++t] = [s, n];
  }), r;
}
var Mm = Nm;
function Pm(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var Vm = Pm, Pn = Ut, Vn = ja, Dm = kr, Lm = Ba, Um = Mm, Hm = Vm, Zm = 1, qm = 2, Bm = "[object Boolean]", Gm = "[object Date]", zm = "[object Error]", Fm = "[object Map]", Km = "[object Number]", Wm = "[object RegExp]", Qm = "[object Set]", Jm = "[object String]", Ym = "[object Symbol]", Xm = "[object ArrayBuffer]", ey = "[object DataView]", Dn = Pn ? Pn.prototype : void 0, dr = Dn ? Dn.valueOf : void 0;
function ty(e, t, r, n, s, i, o) {
  switch (r) {
    case ey:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case Xm:
      return !(e.byteLength != t.byteLength || !i(new Vn(e), new Vn(t)));
    case Bm:
    case Gm:
    case Km:
      return Dm(+e, +t);
    case zm:
      return e.name == t.name && e.message == t.message;
    case Wm:
    case Jm:
      return e == t + "";
    case Fm:
      var c = Um;
    case Qm:
      var u = n & Zm;
      if (c || (c = Hm), e.size != t.size && !u)
        return !1;
      var l = o.get(e);
      if (l)
        return l == t;
      n |= qm, o.set(e, t);
      var f = Lm(c(e), c(t), n, s, i, o);
      return o.delete(e), f;
    case Ym:
      if (dr)
        return dr.call(e) == dr.call(t);
  }
  return !1;
}
var ry = ty, Ln = Ra, ny = 1, ay = Object.prototype, sy = ay.hasOwnProperty;
function iy(e, t, r, n, s, i) {
  var o = r & ny, c = Ln(e), u = c.length, l = Ln(t), f = l.length;
  if (u != f && !o)
    return !1;
  for (var _ = u; _--; ) {
    var S = c[_];
    if (!(o ? S in t : sy.call(t, S)))
      return !1;
  }
  var I = i.get(e), V = i.get(t);
  if (I && V)
    return I == t && V == e;
  var D = !0;
  i.set(e, t), i.set(t, e);
  for (var O = o; ++_ < u; ) {
    S = c[_];
    var M = e[S], ie = t[S];
    if (n)
      var on = o ? n(ie, M, S, t, e, i) : n(M, ie, S, e, t, i);
    if (!(on === void 0 ? M === ie || s(M, ie, r, n, i) : on)) {
      D = !1;
      break;
    }
    O || (O = S == "constructor");
  }
  if (D && !O) {
    var yt = e.constructor, gt = t.constructor;
    yt != gt && "constructor" in e && "constructor" in t && !(typeof yt == "function" && yt instanceof yt && typeof gt == "function" && gt instanceof gt) && (D = !1);
  }
  return i.delete(e), i.delete(t), D;
}
var oy = iy, fr = ha, cy = Ba, uy = ry, ly = oy, Un = zt, Hn = Gt, Zn = Or, dy = _a, fy = 1, qn = "[object Arguments]", Bn = "[object Array]", bt = "[object Object]", py = Object.prototype, Gn = py.hasOwnProperty;
function hy(e, t, r, n, s, i) {
  var o = Hn(e), c = Hn(t), u = o ? Bn : Un(e), l = c ? Bn : Un(t);
  u = u == qn ? bt : u, l = l == qn ? bt : l;
  var f = u == bt, _ = l == bt, S = u == l;
  if (S && Zn(e)) {
    if (!Zn(t))
      return !1;
    o = !0, f = !1;
  }
  if (S && !f)
    return i || (i = new fr()), o || dy(e) ? cy(e, t, r, n, s, i) : uy(e, t, u, r, n, s, i);
  if (!(r & fy)) {
    var I = f && Gn.call(e, "__wrapped__"), V = _ && Gn.call(t, "__wrapped__");
    if (I || V) {
      var D = I ? e.value() : e, O = V ? t.value() : t;
      return i || (i = new fr()), s(D, O, r, n, i);
    }
  }
  return S ? (i || (i = new fr()), ly(e, t, r, n, s, i)) : !1;
}
var vy = hy, my = vy, zn = Ve;
function Ga(e, t, r, n, s) {
  return e === t ? !0 : e == null || t == null || !zn(e) && !zn(t) ? e !== e && t !== t : my(e, t, r, n, Ga, s);
}
var yy = Ga, gy = yy;
function _y(e, t) {
  return gy(e, t);
}
var by = _y;
const Ty = /* @__PURE__ */ ca(by), $y = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), pr = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), $e = {
  equals(e, t) {
    return Ty(e, t);
  }
}, Ay = a.object({
  id: a.optional(a.string()),
  // Firebase Firestore ID
  url: a.string(),
  path: a.string(),
  headers: a.array(
    a.object({
      key: a.string(),
      value: a.string(),
      active: a.boolean()
    })
  ),
  params: a.array(
    a.object({
      key: a.string(),
      value: a.string(),
      active: a.boolean()
    })
  ),
  name: a.string(),
  method: a.string(),
  preRequestScript: a.string(),
  testScript: a.string(),
  contentType: a.string(),
  body: a.string(),
  rawParams: a.optional(a.string()),
  auth: a.optional(a.string()),
  httpUser: a.optional(a.string()),
  httpPassword: a.optional(a.string()),
  bearerToken: a.optional(a.string())
}), Fn = A({
  initial: !0,
  schema: Ay
}), Ur = a.object({
  key: a.string(),
  active: a.boolean()
}).and(
  a.union([
    a.object({
      isFile: a.literal(!0),
      value: a.array(a.instanceof(Blob).nullable())
    }),
    a.object({
      isFile: a.literal(!1),
      value: a.string()
    })
  ])
);
a.object({
  contentType: a.literal("multipart/form-data"),
  body: a.array(Ur)
});
const Sy = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(Ur).catch([])
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), fe = a.object({
  authType: a.literal("none")
}), pe = a.object({
  authType: a.literal("basic"),
  username: a.string().catch(""),
  password: a.string().catch("")
}), he = a.object({
  authType: a.literal("bearer"),
  token: a.string().catch("")
}), Ey = a.object({
  authType: a.literal("oauth-2"),
  token: a.string().catch(""),
  oidcDiscoveryURL: a.string().catch(""),
  authURL: a.string().catch(""),
  accessTokenURL: a.string().catch(""),
  clientID: a.string().catch(""),
  scope: a.string().catch("")
}), Hr = a.object({
  authType: a.literal("api-key"),
  key: a.string().catch(""),
  value: a.string().catch(""),
  addTo: a.string().catch("Headers")
}), ve = a.object({
  authType: a.literal("inherit")
}), xy = a.discriminatedUnion("authType", [
  fe,
  ve,
  pe,
  he,
  Ey,
  Hr
]).and(
  a.object({
    authActive: a.boolean()
  })
), wy = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), za = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Fa = a.object({
  v: a.literal("1"),
  id: a.optional(a.string()),
  // Firebase Firestore ID
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  params: wy,
  headers: za,
  preRequestScript: a.string().catch(""),
  testScript: a.string().catch(""),
  auth: xy,
  body: Sy
});
function Ry(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function ky(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const jy = A({
  initial: !1,
  schema: Fa,
  up(e) {
    const {
      url: t,
      path: r,
      headers: n,
      params: s,
      name: i,
      method: o,
      preRequestScript: c,
      testScript: u
    } = e, l = `${t}${r}`, f = Ry(e), _ = ky(e), S = {
      v: "1",
      endpoint: l,
      headers: n,
      params: s,
      name: i,
      method: o,
      preRequestScript: c,
      testScript: u,
      body: f,
      auth: _
    };
    return e.id && (S.id = e.id), S;
  }
}), Kt = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Ka = Fa.extend({
  v: a.literal("2"),
  requestVariables: Kt
}), Oy = A({
  initial: !1,
  schema: Ka,
  up(e) {
    return {
      ...e,
      v: "2",
      requestVariables: []
    };
  }
}), Wa = a.object({
  grantType: a.literal("AUTHORIZATION_CODE"),
  authEndpoint: a.string().trim(),
  tokenEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch(""),
  isPKCE: a.boolean(),
  codeVerifierMethod: a.union([a.literal("plain"), a.literal("S256")]).optional()
}), Wt = a.object({
  grantType: a.literal("CLIENT_CREDENTIALS"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch("")
}), Qt = a.object({
  grantType: a.literal("PASSWORD"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  username: a.string().trim(),
  password: a.string().trim(),
  token: a.string().catch("")
}), it = a.object({
  grantType: a.literal("IMPLICIT"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch("")
}), Jt = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Wa,
    Wt,
    Qt,
    it
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Cy = a.discriminatedUnion("authType", [
  fe,
  ve,
  pe,
  he,
  Jt,
  Hr
]).and(
  a.object({
    authActive: a.boolean()
  })
), Qa = Ka.extend({
  v: a.literal("3"),
  auth: Cy
}), Iy = A({
  initial: !1,
  schema: Qa,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: s, authURL: i } = e.auth;
      return {
        ...e,
        v: "3",
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: i,
            tokenEndpoint: r,
            clientID: s,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: "3",
      auth: {
        ...e.auth
      }
    };
  }
}), ot = Hr.extend({
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Ny = a.discriminatedUnion("authType", [
  fe,
  ve,
  pe,
  he,
  Jt,
  ot
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ja = Qa.extend({
  v: a.literal("4"),
  auth: Ny
}), My = A({
  schema: Ja,
  initial: !1,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: "4",
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      auth: {
        ...e.auth
      },
      v: "4"
    };
  }
}), Ya = Wa.extend({
  clientSecret: a.string().optional()
}), Yt = Jt.extend({
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Ya,
    Wt,
    Qt,
    it
  ])
}), Xa = a.discriminatedUnion("authType", [
  fe,
  ve,
  pe,
  he,
  Yt,
  ot
]).and(
  a.object({
    authActive: a.boolean()
  })
), es = Ja.extend({
  v: a.literal("5"),
  auth: Xa
}), Py = A({
  schema: es,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "5"
    };
  }
}), ts = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(Ur).catch([])
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("text/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), rs = es.extend({
  v: a.literal("6"),
  body: ts
}), Vy = A({
  schema: rs,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "6"
    };
  }
}), Zr = Ya.extend({
  refreshToken: a.string().optional()
}), ns = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Zr,
    Wt,
    Qt,
    it
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Xt = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0),
    description: a.string().catch("")
  })
), ct = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0),
    description: a.string().catch("")
  })
), er = a.object({
  authType: a.literal("aws-signature"),
  accessKey: a.string().catch(""),
  secretKey: a.string().catch(""),
  region: a.string().catch(""),
  serviceName: a.string().catch(""),
  serviceToken: a.string().optional(),
  signature: a.object({}).optional(),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), as = a.discriminatedUnion("authType", [
  fe,
  ve,
  pe,
  he,
  ns,
  ot,
  er
]).and(
  a.object({
    authActive: a.boolean()
  })
), ss = rs.extend({
  v: a.literal("7"),
  params: Xt,
  headers: ct,
  auth: as
}), Dy = A({
  schema: ss,
  initial: !1,
  up(e) {
    const t = e.params.map((n) => ({
      ...n,
      description: ""
    })), r = e.headers.map((n) => ({
      ...n,
      description: ""
    }));
    return {
      ...e,
      v: "7",
      params: t,
      headers: r
      // no need to update anything for HoppRESTAuth, because the newly added refreshToken is optional
    };
  }
}), Ly = {
  // 1xx Informational
  // Request received, continuing process.[2]
  // This class of status code indicates a provisional response, consisting only of the Status-Line and optional headers, and is terminated by an empty line. Since HTTP/1.0 did not define any 1xx status codes, servers must not send a 1xx response to an HTTP/1.0 client except under experimental conditions.
  100: "Continue",
  // This means that the server has received the request headers, and that the client should proceed to send the request body (in the case of a request for which a body needs to be sent; for example, a POST request). If the request body is large, sending it to a server when a request has already been rejected based upon inappropriate headers is inefficient. To have a server check if the request could be accepted based on the request's headers alone, a client must send Expect: 100-continue as a header in its initial request[2] and check if a 100 Continue status code is received in response before continuing (or receive 417 Expectation Failed and not continue).[2]
  101: "Switching Protocols",
  // This means the requester has asked the server to switch protocols and the server is acknowledging that it will do so.[2]
  102: "Processing",
  // (WebDAV; RFC 2518) As a WebDAV request may contain many sub-requests involving file operations, it may take a long time to complete the request. This code indicates that the server has received and is processing the request, but no response is available yet.[3] This prevents the client from timing out and assuming the request was lost.
  // 2xx Success
  // This class of status codes indicates the action requested by the client was received, understood, accepted and processed successfully.
  200: "OK",
  // Standard response for successful HTTP requests. The actual response will depend on the request method used. In a GET request, the response will contain an entity corresponding to the requested resource. In a POST request the response will contain an entity describing or containing the result of the action.[2]
  201: "Created",
  // The request has been fulfilled and resulted in a new resource being created.[2]
  202: "Accepted",
  // The request has been accepted for processing, but the processing has not been completed. The request might or might not eventually be acted upon, as it might be disallowed when processing actually takes place.[2]
  203: "Non-Authoritative Information",
  // (since HTTP/1.1) The server successfully processed the request, but is returning information that may be from another source.[2]
  204: "No Content",
  // The server successfully processed the request, but is not returning any content.[2]
  205: "Reset Content",
  // The server successfully processed the request, but is not returning any content. Unlike a 204 response, this response requires that the requester reset the document view.[2]
  206: "Partial Content",
  // The server is delivering only part of the resource due to a range header sent by the client. The range header is used by tools like wget to enable resuming of interrupted downloads, or split a download into multiple simultaneous streams.[2]
  207: "Multi-Status",
  // (WebDAV; RFC 4918) The message body that follows is an XML message and can contain a number of separate response codes, depending on how many sub-requests were made.[4]
  208: "Already Reported",
  // (WebDAV; RFC 5842) The members of a DAV binding have already been enumerated in a previous reply to this request, and are not being included again.
  226: "IM Used",
  // (RFC 3229) The server has fulfilled a GET request for the resource, and the response is a representation of the result of one or more instance-manipulations applied to the current instance. [5]
  // 3xx Redirection
  // The client must take additional action to complete the request.[2]
  // This class of status code indicates that further action needs to be taken by the user agent in order to fulfil the request. The action required may be carried out by the user agent without interaction with the user if and only if the method used in the second request is GET or HEAD. A user agent should not automatically redirect a request more than five times, since such redirections usually indicate an infinite loop.
  300: "Multiple Choices",
  // Indicates multiple options for the resource that the client may follow. It, for instance, could be used to present different format options for video, list files with different extensions, or word sense disambiguation.[2]
  301: "Moved Permanently",
  // This and all future requests should be directed to the given URI.[2]
  302: "Found",
  // This is an example of industry practice contradicting the standard.[2] The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect (the original describing phrase was "Moved Temporarily"),[6] but popular browsers implemented 302 with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307 to distinguish between the two behaviours.[7] However, some Web applications and frameworks use the 302 status code as if it were the 303.[citation needed]
  303: "See Other",
  // (since HTTP/1.1) The response to the request can be found under another URI using a GET method. When received in response to a POST (or PUT/DELETE), it should be assumed that the server has received the data and the redirect should be issued with a separate GET message.[2]
  304: "Not Modified",
  // Indicates the resource has not been modified since last requested.[2] Typically, the HTTP client provides a header like the If-Modified-Since header to provide a time against which to compare. Using this saves bandwidth and reprocessing on both the server and client, as only the header data must be sent and received in comparison to the entirety of the page being re-processed by the server, then sent again using more bandwidth of the server and client.
  305: "Use Proxy",
  // (since HTTP/1.1) Many HTTP clients (such as Mozilla[8] and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.[2]
  306: "Switch Proxy",
  // No longer used.[2] Originally meant "Subsequent requests should use the specified proxy."[9]
  307: "Temporary Redirect",
  // (since HTTP/1.1) In this case, the request should be repeated with another URI; however, future requests can still use the original URI.[2] In contrast to 302, the request method should not be changed when reissuing the original request. For instance, a POST request must be repeated using another POST request.
  308: "Permanent Redirect",
  // (experimental Internet-Draft)[10] The request, and all future requests should be repeated using another URI. 307 and 308 (as proposed) parallel the behaviours of 302 and 301, but do not require the HTTP method to change. So, for example, submitting a form to a permanently redirected resource may continue smoothly.
  // 4xx Client Error
  // The 4xx class of status code is intended for cases in which the client seems to have erred. Except when responding to a HEAD request, the server should include an entity containing an explanation of the error situation, and whether it is a temporary or permanent condition. These status codes are applicable to any request method. User agents should display any included entity to the user.
  400: "Bad Request",
  // The request cannot be fulfilled due to bad syntax.[2]
  401: "Unauthorized",
  // Similar to 403 Forbidden, but specifically for use when authentication is possible but has failed or not yet been provided.[2] The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource. See Basic access authentication and Digest access authentication.
  402: "Payment Required",
  // Reserved for future use.[2] The original intention was that this code might be used as part of some form of digital cash or micropayment scheme, but that has not happened, and this code is not usually used. As an example of its use, however, Apple's MobileMe service generates a 402 error ("httpStatusCode:402" in the Mac OS X Console log) if the MobileMe account is delinquent.[citation needed]
  403: "Forbidden",
  // The request was a legal request, but the server is refusing to respond to it.[2] Unlike a 401 Unauthorized response, authenticating will make no difference.[2]
  404: "Not Found",
  // The requested resource could not be found but may be available again in the future.[2] Subsequent requests by the client are permissible.
  405: "Method Not Allowed",
  // A request was made of a resource using a request method not supported by that resource;[2] for example, using GET on a form which requires data to be presented via POST, or using PUT on a read-only resource.
  406: "Not Acceptable",
  // The requested resource is only capable of generating content not acceptable according to the Accept headers sent in the request.[2]
  407: "Proxy Authentication Required",
  // The client must first authenticate itself with the proxy.[2]
  408: "Request Timeout",
  // The server timed out waiting for the request.[2] According to W3 HTTP specifications: "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
  409: "Conflict",
  // Indicates that the request could not be processed because of conflict in the request, such as an edit conflict.[2]
  410: "Gone",
  // Indicates that the resource requested is no longer available and will not be available again.[2] This should be used when a resource has been intentionally removed and the resource should be purged. Upon receiving a 410 status code, the client should not request the resource again in the future. Clients such as search engines should remove the resource from their indices. Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
  411: "Length Required",
  // The request did not specify the length of its content, which is required by the requested resource.[2]
  412: "Precondition Failed",
  // The server does not meet one of the preconditions that the requester put on the request.[2]
  413: "Request Entity Too Large",
  // The request is larger than the server is willing or able to process.[2]
  414: "Request-URI Too Long",
  // The URI provided was too long for the server to process.[2]
  415: "Unsupported Media Type",
  // The request entity has a media type which the server or resource does not support.[2] For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
  416: "Requested Range Not Satisfiable",
  // The client has asked for a portion of the file, but the server cannot supply that portion.[2] For example, if the client asked for a part of the file that lies beyond the end of the file.[2]
  417: "Expectation Failed",
  // The server cannot meet the requirements of the Expect request-header field.[2]
  418: "I'm a teapot",
  // (RFC 2324) This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol, and is not expected to be implemented by actual HTTP servers. However, known implementations do exist.[11]
  420: "Enhance Your Calm",
  // (Twitter) Returned by the Twitter Search and Trends API when the client is being rate limited.[12] Likely a reference to this number's association with marijuana. Other services may wish to implement the 429 Too Many Requests response code instead. The phrase "Enhance Your Calm" is a reference to Demolition Man (film). In the film, Sylvester Stallone's character John Spartan is a hot-head in a generally more subdued future, and is regularly told to "Enhance your calm" rather than a more common phrase like "calm down".
  422: "Unprocessable Entity",
  // (WebDAV; RFC 4918) The request was well-formed but was unable to be followed due to semantic errors.[4]
  423: "Locked",
  // (WebDAV; RFC 4918) The resource that is being accessed is locked.[4]
  424: "Failed Dependency",
  // (WebDAV; RFC 4918) The request failed due to failure of a previous request (e.g. a PROPPATCH).[4]
  425: "Unordered Collection",
  // (Internet draft) Defined in drafts of "WebDAV Advanced Collections Protocol",[14] but not present in "Web Distributed Authoring and Versioning (WebDAV) Ordered Collections Protocol".[15]
  426: "Upgrade Required",
  // (RFC 2817) The client should switch to a different protocol such as TLS/1.0.[16]
  428: "Precondition Required",
  // (RFC 6585) The origin server requires the request to be conditional. Intended to prevent "the 'lost update' problem, where a client GETs a resource's state, modifies it, and PUTs it back to the server, when meanwhile a third party has modified the state on the server, leading to a conflict."[17]
  429: "Too Many Requests",
  // (RFC 6585) The user has sent too many requests in a given amount of time. Intended for use with rate limiting schemes.[17]
  431: "Request Header Fields Too Large",
  // (RFC 6585) The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.[17]
  444: "No Response",
  // (Nginx) Used in Nginx logs to indicate that the server has returned no information to the client and closed the connection (useful as a deterrent for malware).
  449: "Retry With",
  // (Microsoft) A Microsoft extension. The request should be retried after performing the appropriate action.[18] Often search-engines or custom applications will ignore required parameters. Where no default action is appropriate, the Aviongoo website sends a "HTTP/1.1 449 Retry with valid parameters: param1, param2, . . ." response. The applications may choose to learn, or not.
  450: "Blocked by Windows Parental Controls",
  // (Microsoft) A Microsoft extension. This error is given when Windows Parental Controls are turned on and are blocking access to the given webpage.[19]
  451: "Unavailable For Legal Reasons",
  // (Internet draft) Defined in the internet draft "A New HTTP Status Code for Legally-restricted Resources",[20]. Intended to be used when resource access is denied for legal reasons, e.g. censorship or government-mandated blocked access. Likely a reference to the 1953 dystopian novel Fahrenheit 451, where books are outlawed.
  499: "Client Closed Request",
  // (Nginx) Used in Nginx logs to indicate when the connection has been closed by client while the server is still processing its request, making server unable to send a status code back.[21]
  // 5xx Server Error
  // The server failed to fulfill an apparently valid request.[2]
  // Response status codes beginning with the digit "5" indicate cases in which the server is aware that it has encountered an error or is otherwise incapable of performing the request. Except when responding to a HEAD request, the server should include an entity containing an explanation of the error situation, and indicate whether it is a temporary or permanent condition. Likewise, user agents should display any included entity to the user. These response codes are applicable to any request method.
  500: "Internal Server Error",
  // A generic error message, given when no more specific message is suitable.[2]
  501: "Not Implemented",
  // The server either does not recognise the request method, or it lacks the ability to fulfill the request.[2]
  502: "Bad Gateway",
  // The server was acting as a gateway or proxy and received an invalid response from the upstream server.[2]
  503: "Service Unavailable",
  // The server is currently unavailable (because it is overloaded or down for maintenance).[2] Generally, this is a temporary state.
  504: "Gateway Timeout",
  // The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.[2]
  505: "HTTP Version Not Supported",
  // The server does not support the HTTP protocol version used in the request.[2]
  506: "Variant Also Negotiates",
  // (RFC 2295) Transparent content negotiation for the request results in a circular reference.[22]
  507: "Insufficient Storage",
  // (WebDAV; RFC 4918) The server is unable to store the representation needed to complete the request.[4]
  508: "Loop Detected",
  // (WebDAV; RFC 5842) The server detected an infinite loop while processing the request (sent in lieu of 208).
  509: "Bandwidth Limit Exceeded",
  // (Apache bw/limited extension) This status code, while used by many servers, is not specified in any RFCs.
  510: "Not Extended",
  // (RFC 2774) Further extensions to the request are required for the server to fulfill it.[23]
  511: "Network Authentication Required",
  // (RFC 6585) The client needs to authenticate to gain network access. Intended for use by intercepting proxies used to control access to the network (e.g. "captive portals" used to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).[17]
  598: "Network read timeout error",
  // (Unknown) This status code is not specified in any RFCs, but is used by Microsoft Corp. HTTP proxies to signal a network read timeout behind the proxy to a client in front of the proxy.
  599: "Network connect timeout error"
  // (Unknown) This status code is not specified in any RFCs, but is used by Microsoft Corp. HTTP proxies to signal a network connect timeout behind the proxy to a client in front of the proxy.
}, is = Wt.extend({
  clientSecret: a.string().optional()
}), os = Qt.extend({
  clientSecret: a.string().optional()
}), Uy = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Zr,
    is,
    os,
    it
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), cs = a.object({
  authType: a.literal("digest"),
  username: a.string().catch(""),
  password: a.string().catch(""),
  realm: a.string().catch(""),
  nonce: a.string().catch(""),
  algorithm: a.enum(["MD5", "MD5-sess"]).catch("MD5"),
  qop: a.enum(["auth", "auth-int"]).catch("auth"),
  nc: a.string().catch(""),
  cnonce: a.string().catch(""),
  opaque: a.string().catch(""),
  disableRetry: a.boolean().catch(!1)
}), tr = a.discriminatedUnion("authType", [
  fe,
  ve,
  pe,
  he,
  Uy,
  ot,
  er,
  cs
]).and(
  a.object({
    authActive: a.boolean()
  })
), us = a.union(
  Object.keys(Ly).map((e) => a.literal(parseInt(e)))
), ls = a.array(
  a.object({
    key: a.string(),
    value: a.string()
  })
), Hy = a.object({
  v: a.literal("1"),
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  headers: ct,
  params: Xt,
  body: ts,
  auth: tr,
  requestVariables: Kt
}), Zy = a.object({
  name: a.string(),
  originalRequest: Hy,
  status: a.string(),
  code: a.optional(us),
  headers: ls,
  body: a.string()
}), qy = a.record(
  a.string(),
  Zy
), ds = ss.extend({
  v: a.literal("8"),
  auth: tr,
  responses: qy
}), By = A({
  schema: ds,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "8",
      // no need to update anything for HoppRESTAuth, because we loosened the previous schema by making `clientSecret` optional
      responses: {}
    };
  }
}), fs = a.object({
  key: a.string(),
  active: a.boolean(),
  contentType: a.string().optional().catch(void 0)
}).and(
  a.union([
    a.object({
      isFile: a.literal(!0),
      value: a.array(a.instanceof(Blob).nullable()).catch([])
    }),
    a.object({
      isFile: a.literal(!1),
      value: a.string()
    })
  ])
).transform((e) => e.isFile && Array.isArray(e.value) && e.value.length === 0 ? {
  ...e,
  isFile: !1,
  value: ""
} : e), ps = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(fs).catch([]),
    showIndividualContentType: a.boolean().optional().catch(!1)
  }),
  a.object({
    contentType: a.literal("application/octet-stream"),
    body: a.instanceof(File).nullable().catch(null)
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("text/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("binary"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), hs = a.object({
  v: a.literal("2"),
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  headers: ct,
  params: Xt,
  body: ps,
  auth: tr,
  requestVariables: Kt
}), vs = a.object({
  name: a.string(),
  originalRequest: hs,
  status: a.string(),
  code: a.optional(us),
  headers: ls,
  body: a.string()
}), Gy = a.record(
  a.string(),
  vs
), ms = ds.extend({
  v: a.literal("9"),
  body: ps,
  responses: Gy
}), zy = A({
  schema: ms,
  initial: !1,
  up(e) {
    const t = Object.fromEntries(
      Object.entries(e.responses).map(([r, n]) => [
        r,
        {
          ...n,
          originalRequest: {
            ...n.originalRequest,
            v: "2"
          }
        }
      ])
    );
    return {
      ...e,
      v: "9",
      responses: t
    };
  }
}), qr = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(fs).catch([]),
    showIndividualContentType: a.boolean().optional().catch(!1),
    isBulkEditing: a.boolean().optional().catch(!1)
  }),
  a.object({
    contentType: a.literal("application/octet-stream"),
    body: a.instanceof(File).nullable().catch(null)
  }),
  a.object({
    contentType: a.literal("application/x-www-form-urlencoded"),
    body: a.string().catch(""),
    isBulkEditing: a.boolean().optional().catch(!1)
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("text/xml"),
      a.literal("binary"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), ys = ms.extend({
  v: a.literal("10"),
  body: qr
}), Fy = A({
  schema: ys,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "10"
    };
  }
}), Ky = is.extend({
  clientAuthentication: a.enum(["AS_BASIC_AUTH_HEADERS", "IN_BODY"])
}), gs = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Zr,
    Ky,
    os,
    it
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), rr = a.discriminatedUnion("authType", [
  fe,
  ve,
  pe,
  he,
  gs,
  ot,
  er,
  cs
]).and(
  a.object({
    authActive: a.boolean()
  })
), Wy = hs.extend({
  v: a.literal("3"),
  auth: rr,
  body: qr
}), Qy = vs.extend({
  originalRequest: Wy
}), _s = a.record(
  a.string(),
  Qy
), Jy = ys.extend({
  v: a.literal("11"),
  auth: rr,
  responses: _s
}), Yy = A({
  schema: Jy,
  initial: !1,
  up(e) {
    const t = e.auth, r = Object.fromEntries(
      Object.entries(e.responses).map(([n, s]) => [
        n,
        {
          ...s,
          originalRequest: {
            ...s.originalRequest,
            auth: t.authType === "oauth-2" ? {
              ...t,
              grantTypeInfo: t.grantTypeInfo.grantType === "CLIENT_CREDENTIALS" ? {
                ...t.grantTypeInfo,
                clientAuthentication: "IN_BODY"
              } : t.grantTypeInfo
            } : t,
            // just following the previous pattern here, but is this a good idea to overwrite the request version ?
            v: "3"
          }
        }
      ])
    );
    return {
      ...e,
      v: "11",
      auth: t.authType === "oauth-2" ? {
        ...t,
        grantTypeInfo: t.grantTypeInfo.grantType === "CLIENT_CREDENTIALS" ? {
          ...t.grantTypeInfo,
          clientAuthentication: "IN_BODY"
        } : t.grantTypeInfo
      } : t,
      responses: r
    };
  }
}), Xy = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "text/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "application/octet-stream": "binary",
  "text/html": "html",
  "text/plain": "plain"
}, nb = Object.keys(
  Xy
), eg = a.object({
  // v is a stringified number
  v: a.string().regex(/^\d+$/).transform(Number)
}), nr = st({
  latestVersion: 11,
  versionMap: {
    0: Fn,
    1: jy,
    2: Oy,
    3: Iy,
    4: My,
    5: Py,
    6: Vy,
    7: Dy,
    8: By,
    9: zy,
    10: Fy,
    11: Yy
  },
  getVersion(e) {
    const t = eg.safeParse(e);
    return t.success ? t.data.v : Fn.schema.safeParse(e).success ? 0 : null;
  }
}), tg = _i({
  id: $y(Q),
  v: Q,
  auth: $e,
  body: $e,
  endpoint: Q,
  headers: pr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    $e
  ),
  params: pr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    $e
  ),
  method: Q,
  name: Q,
  preRequestScript: Q,
  testScript: Q,
  requestVariables: pr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    $e
  ),
  responses: $e
}), bs = "11", ab = tg.equals;
function sb(e, t) {
  const r = dv(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = qr.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = rr.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = Xt.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = ct.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
    if ("requestVariables" in e) {
      const n = Kt.safeParse(e.requestVariables);
      n.success && (r.requestVariables = n.data);
    }
    if ("responses" in e) {
      const n = _s.safeParse(e.responses);
      n.success && (r.responses = n.data);
    }
  }
  return r;
}
function ib(e) {
  return {
    v: bs,
    ...e
  };
}
function rg() {
  return {
    v: bs,
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "inherit",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    },
    requestVariables: [],
    responses: {}
  };
}
function ob(e) {
  return nr.isLatest(e);
}
function ng(e) {
  const t = nr.safeParse(e);
  return t.type === "ok" ? t.value : rg();
}
const Br = a.object({
  key: a.string().catch(""),
  value: a.string().catch(""),
  active: a.boolean().catch(!0)
}), ag = a.object({
  v: a.literal(1),
  name: a.string(),
  url: a.string(),
  headers: a.array(Br).catch([]),
  query: a.string(),
  variables: a.string()
}), sg = A({
  initial: !0,
  schema: ag
}), me = a.object({
  authType: a.literal("none")
}), ye = a.object({
  authType: a.literal("basic"),
  username: a.string().catch(""),
  password: a.string().catch("")
}), ge = a.object({
  authType: a.literal("bearer"),
  token: a.string().catch("")
}), ig = a.object({
  authType: a.literal("oauth-2"),
  token: a.string().catch(""),
  oidcDiscoveryURL: a.string().catch(""),
  authURL: a.string().catch(""),
  accessTokenURL: a.string().catch(""),
  clientID: a.string().catch(""),
  scope: a.string().catch("")
}), Gr = a.object({
  authType: a.literal("api-key"),
  key: a.string().catch(""),
  value: a.string().catch(""),
  addTo: a.string().catch("Headers")
}), _e = a.object({
  authType: a.literal("inherit")
}), og = a.discriminatedUnion("authType", [
  me,
  ye,
  ge,
  ig,
  Gr,
  _e
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ts = a.object({
  id: a.optional(a.string()),
  v: a.literal(2),
  name: a.string(),
  url: a.string(),
  headers: a.array(Br).catch([]),
  query: a.string(),
  variables: a.string(),
  auth: og
}), cg = A({
  initial: !1,
  schema: Ts,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), ug = a.discriminatedUnion("authType", [
  me,
  _e,
  ye,
  ge,
  Gr,
  Jt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), $s = Ts.extend({
  v: a.literal(3),
  auth: ug
}), lg = A({
  initial: !1,
  schema: $s,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: s, authURL: i } = e.auth;
      return {
        ...e,
        v: 3,
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: i,
            tokenEndpoint: r,
            clientID: s,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: 3,
      auth: {
        ...e.auth
      }
    };
  }
}), ut = Gr.extend({
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), dg = a.discriminatedUnion("authType", [
  me,
  _e,
  ye,
  ge,
  ut,
  Yt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), As = $s.extend({
  v: a.literal(4),
  auth: dg
}), fg = A({
  initial: !1,
  schema: As,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: 4,
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      v: 4,
      auth: {
        ...e.auth
      }
    };
  }
}), Ss = a.discriminatedUnion("authType", [
  me,
  _e,
  ye,
  ge,
  ut,
  Yt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), Es = As.extend({
  v: a.literal(5),
  auth: Ss
}), pg = A({
  initial: !1,
  schema: Es,
  up(e) {
    return {
      ...e,
      v: 5
    };
  }
}), zr = er, xs = a.object({
  key: a.string().catch(""),
  value: a.string().catch(""),
  active: a.boolean().catch(!0),
  description: a.string().catch("")
}), ws = a.discriminatedUnion("authType", [
  me,
  _e,
  ye,
  ge,
  Yt,
  ut,
  zr
]).and(
  a.object({
    authActive: a.boolean()
  })
), Rs = Es.extend({
  v: a.literal(6),
  auth: ws,
  headers: a.array(xs).catch([])
}), hg = A({
  schema: Rs,
  initial: !1,
  up(e) {
    const t = e.headers.map((r) => ({
      ...r,
      description: ""
    }));
    return {
      ...e,
      v: 6,
      headers: t
    };
  }
}), ks = a.discriminatedUnion("authType", [
  me,
  _e,
  ye,
  ge,
  ns,
  ut,
  zr
]).and(
  a.object({
    authActive: a.boolean()
  })
), js = Rs.extend({
  v: a.literal(7),
  auth: ks
}), vg = A({
  schema: js,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: 7
      // no need to update anything for HoppGQLAuth, because we loosened the previous schema by making `clientSecret` optional
    };
  }
}), Os = a.discriminatedUnion("authType", [
  me,
  _e,
  ye,
  ge,
  gs,
  ut,
  zr
]).and(
  a.object({
    authActive: a.boolean()
  })
), mg = js.extend({
  v: a.literal(8),
  auth: Os
}), yg = A({
  schema: mg,
  initial: !1,
  up(e) {
    const t = e.auth;
    return {
      ...e,
      v: 8,
      auth: t.authType === "oauth-2" ? {
        ...t,
        grantTypeInfo: t.grantTypeInfo.grantType === "CLIENT_CREDENTIALS" ? {
          ...t.grantTypeInfo,
          clientAuthentication: "IN_BODY"
        } : t.grantTypeInfo
      } : t
    };
  }
}), Cs = 8, gg = a.object({
  v: a.number()
}), Fr = st({
  latestVersion: 8,
  versionMap: {
    1: sg,
    2: cg,
    3: lg,
    4: fg,
    5: pg,
    6: hg,
    7: vg,
    8: yg
  },
  getVersion(e) {
    const t = gg.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), _g = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function bg() {
  return {
    v: Cs,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: _g,
    auth: {
      authType: "inherit",
      authActive: !0
    }
  };
}
function Tg(e) {
  const t = Fr.safeParse(e);
  return t.type === "ok" ? t.value : bg();
}
function cb(e) {
  return {
    v: Cs,
    ...e
  };
}
const $g = a.object({
  v: a.literal(1),
  id: a.optional(a.string()),
  // For Firestore ID data
  name: a.string(),
  requests: a.array(
    a.lazy(
      () => a.union([
        It(nr),
        It(Fr)
      ])
    )
  )
}), Is = $g.extend({
  folders: a.lazy(() => a.array(Is))
}), Kn = A({
  initial: !0,
  schema: Is
}), Ns = a.object({
  v: a.literal(2),
  id: a.optional(a.string()),
  // For Firestore ID data
  name: a.string(),
  requests: a.array(
    a.lazy(
      () => a.union([
        It(nr),
        It(Fr)
      ])
    )
  ),
  auth: a.union([Xa, Ss]),
  headers: a.union([za, a.array(Br)])
}), Ms = Ns.extend({
  folders: a.lazy(() => a.array(Ms))
}), Ag = A({
  initial: !1,
  schema: Ms,
  up(e) {
    const t = {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "inherit"
      },
      headers: []
    };
    return e.id && (t.id = e.id), t;
  }
}), Ps = Ns.extend({
  v: a.literal(3),
  // AWS Signature Authorization type addition
  auth: a.union([as, ws]),
  // `description` field addition under `headers`
  headers: a.union([ct, a.array(xs)])
}), Vs = Ps.extend({
  folders: a.lazy(() => a.array(Vs))
}), Sg = A({
  initial: !1,
  schema: Vs,
  up(e) {
    const t = e.headers.map(
      (n) => ({
        ...n,
        description: ""
      })
    );
    return {
      ...e,
      v: 3,
      headers: t
    };
  }
}), Ds = Ps.extend({
  v: a.literal(4),
  auth: a.union([tr, ks])
}), Ls = Ds.extend({
  folders: a.lazy(() => a.array(Ls))
}), Eg = A({
  initial: !1,
  schema: Ls,
  // @ts-expect-error
  up(e) {
    return {
      ...e,
      v: 4
    };
  }
});
var C = [];
for (var hr = 0; hr < 256; ++hr)
  C.push((hr + 256).toString(16).slice(1));
function xg(e, t = 0) {
  return (C[e[t + 0]] + C[e[t + 1]] + C[e[t + 2]] + C[e[t + 3]] + "-" + C[e[t + 4]] + C[e[t + 5]] + "-" + C[e[t + 6]] + C[e[t + 7]] + "-" + C[e[t + 8]] + C[e[t + 9]] + "-" + C[e[t + 10]] + C[e[t + 11]] + C[e[t + 12]] + C[e[t + 13]] + C[e[t + 14]] + C[e[t + 15]]).toLowerCase();
}
var Tt, wg = new Uint8Array(16);
function Rg() {
  if (!Tt && (Tt = typeof crypto < "u" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !Tt))
    throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return Tt(wg);
}
var kg = typeof crypto < "u" && crypto.randomUUID && crypto.randomUUID.bind(crypto);
const Wn = {
  randomUUID: kg
};
function jg(e, t, r) {
  if (Wn.randomUUID && !t && !e)
    return Wn.randomUUID();
  e = e || {};
  var n = e.random || (e.rng || Rg)();
  return n[6] = n[6] & 15 | 64, n[8] = n[8] & 63 | 128, xg(n);
}
const Us = (e = "") => {
  const t = Date.now().toString(36), r = jg();
  return `${e}_${t}_${r}`;
}, Hs = Ds.extend({
  v: a.literal(5),
  _ref_id: a.string().optional()
}), Zs = Hs.extend({
  folders: a.lazy(() => a.array(Zs))
}), Og = A({
  initial: !1,
  schema: Zs,
  // @ts-expect-error
  up(e) {
    return {
      ...e,
      v: 5,
      _ref_id: Us("coll")
    };
  }
}), Cg = Hs.extend({
  v: a.literal(6),
  auth: a.union([rr, Os])
}), qs = Cg.extend({
  folders: a.lazy(() => a.array(qs))
}), Ig = A({
  initial: !1,
  schema: qs,
  // @ts-expect-error
  up(e) {
    const t = e.auth, r = t.authType === "oauth-2" ? {
      ...t,
      grantTypeInfo: t.grantTypeInfo.grantType === "CLIENT_CREDENTIALS" ? {
        ...t.grantTypeInfo,
        clientAuthentication: "IN_BODY"
      } : t.grantTypeInfo
    } : t;
    return {
      ...e,
      auth: r,
      v: 6
    };
  }
}), Ng = a.object({
  v: a.number()
}), ub = st({
  latestVersion: 6,
  versionMap: {
    1: Kn,
    2: Ag,
    3: Sg,
    4: Eg,
    5: Og,
    6: Ig
  },
  getVersion(e) {
    const t = Ng.safeParse(e);
    return t.success ? t.data.v : Kn.schema.safeParse(e).success ? 1 : null;
  }
}), Mg = 6;
function Bs(e) {
  return {
    v: Mg,
    ...e,
    _ref_id: e._ref_id ? e._ref_id : Us("coll")
  };
}
function Pg(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Pg), n = (e.requests ?? []).map(ng), s = e.auth ?? { authType: "inherit", authActive: !0 }, i = e.headers ?? [], o = Bs({
    name: t,
    folders: r,
    requests: n,
    auth: s,
    headers: i
  });
  return e.id && (o.id = e.id), e._ref_id && (o._ref_id = e._ref_id), o;
}
function Vg(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Vg), n = (e.requests ?? []).map(Tg), s = e.auth ?? { authType: "inherit", authActive: !0 }, i = e.headers ?? [], o = Bs({
    name: t,
    folders: r,
    requests: n,
    auth: s,
    headers: i
  });
  return e.id && (o.id = e.id), e._ref_id && (o._ref_id = e._ref_id), o;
}
var ae = function(e) {
  return function(t) {
    return !e(t);
  };
}, Dg = Ni, Lg = Ci;
function Gs(e, t) {
  return t === void 0 ? function(r) {
    return Gs(e, r);
  } : Lg(e, t) ? aa : sa(t[e]);
}
var zs = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, Ug = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var s = e(n, t[n]);
      na(s) && r.push(s.value);
    }
    return r;
  };
}, Hg = function(e) {
  return Ug(function(t, r) {
    return e(r);
  });
}, Fs = function(e) {
  return e.slice();
}, Zg = Oi, Qn = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, qg = function(e) {
  return function(t) {
    return Qn(Qn([], t, !0), [e], !1);
  };
}, Bg = qg, Gg = function(e) {
  return [e];
}, zg = Bg, Fg = Gs, Kg = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, Wg = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, Qg = function() {
  return {
    concat: Wg().concat,
    empty: []
  };
}, ar = aa, lt = sa;
function Ks(e) {
  return function(t) {
    return e(t) ? lt(t) : ar;
  };
}
var Sr = function(e) {
  return function(t) {
    return Ws(t) ? ar : lt(e(t.value));
  };
}, Jg = na, Ws = function(e) {
  return e._tag === "None";
}, Yg = function(e, t) {
  return function(r) {
    return Ws(r) ? e() : t(r.value);
  };
}, Xg = Yg, Kr = Xg, e_ = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, se = $i, z = Ai, t_ = /* @__PURE__ */ yi(2, function(e, t) {
  return ke(e) ? e : t(e.right);
}), dt = function(e) {
  return function(t) {
    return ke(t) ? t : z(e(t.right));
  };
}, sr = function(e) {
  return function(t) {
    return ke(t) ? se(e(t.left)) : t;
  };
}, ke = bi, r_ = Ti, n_ = function(e) {
  return function(t) {
    return ke(t) ? e(t.left) : t.right;
  };
}, Wr = n_, Jn = t_, Mt = function() {
  return Mt = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var s in t) Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, Mt.apply(this, arguments);
}, ft = function(e, t, r) {
  return z({
    value: e,
    next: t,
    start: r
  });
}, pt = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), se({
    input: e,
    expected: t,
    fatal: r
  });
}, a_ = function(e, t) {
  return Mt(Mt({}, e), { expected: t });
}, s_ = function(e, t) {
  return i_().concat(e, t);
}, i_ = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? ki().concat(e, t) : e.input.cursor > t.input.cursor ? cr().concat(e, t) : Ri({
        input: cr(),
        fatal: cr(),
        expected: Qg()
      }).concat(e, t);
    }
  };
}, o_ = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, c_ = function(e) {
  return Fg(e.cursor, e.buffer);
}, u_ = function(e) {
  return e.cursor >= e.buffer.length;
}, l_ = function(e) {
  return g(c_(e), Sr(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, ne = function() {
  return ne = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var s in t) Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, ne.apply(this, arguments);
}, Qr = function(e) {
  return function(t) {
    return ft(e, t, t);
  };
}, d_ = function() {
  return function(e) {
    return pt(e);
  };
}, f_ = function(e) {
  return function() {
    return pt(e);
  };
}, j = function(e) {
  return g(v_(p_()), be(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? je(r) : f_(n);
  }));
}, k = function(e, t) {
  return function(r) {
    return g(e(r), sr(function(n) {
      return a_(n, [t]);
    }));
  };
}, p_ = function() {
  return function(e) {
    return g(l_(e), Kr(function() {
      return pt(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return ft(r, n, e);
    }));
  };
}, h_ = function(e, t) {
  return function(r) {
    return g(e(r), Jn(function(n) {
      return g(t(n.value)(n.next), Jn(function(s) {
        return ft(s.value, s.next, r);
      }));
    }));
  };
}, ir = function(e, t) {
  return function(r) {
    var n = e(r);
    return r_(n) || n.left.fatal ? n : g(t()(r), sr(function(s) {
      return s_(n.left, s);
    }));
  };
}, v_ = function(e) {
  return function(t) {
    return g(e(t), dt(function(r) {
      return ne(ne({}, r), { value: [r.value, t] });
    }));
  };
}, Qs = function(e) {
  return Te(function() {
    return je(e.empty);
  });
}, Jr = function() {
  return k(function(e) {
    return u_(e) ? ft(void 0, e, e) : pt(e);
  }, "end of file");
}, m_ = function(e) {
  return g(Yr(e), Te(function() {
    return je([]);
  }));
}, Yr = function(e) {
  return g(e, be(function(t) {
    return en(Gg(t), function(r) {
      return g(e, R(function(n) {
        return se(zg(n)(r));
      }), Te(function() {
        return je(z(r));
      }));
    });
  }));
}, y_ = function(e, t) {
  return function(r) {
    return g(e, be(function() {
      return r;
    }), tn(function() {
      return t;
    }));
  };
}, Js = function(e) {
  return y_(e, e);
}, g_ = function(e) {
  return m_(j(ae(e)));
}, Xr = function(e, t) {
  return g(t, R(function() {
    return Zg;
  }), Te(function() {
    return __(e, t);
  }));
}, __ = function(e, t) {
  return g(e, be(function(r) {
    return en(Mi(r), function(n) {
      return g(t, R(function() {
        return z(n);
      }), Te(function() {
        return g(e, R(function(s) {
          return se(Dg(s)(n));
        }));
      }));
    });
  }));
}, ht = function(e, t) {
  return function(r) {
    return g(e(r), dt(function(n) {
      return ne(ne({}, n), { value: t(n.value) });
    }));
  };
}, Ys = function(e, t) {
  return or(e, function(r) {
    return ht(t, r);
  });
}, or = function(e, t) {
  return h_(e, t);
}, en = function(e, t) {
  var r = function(n) {
    return function(s) {
      return ke(s.value) ? se({ value: s.value.left, stream: s.next }) : z(ft(s.value.right, s.next, n));
    };
  };
  return function(n) {
    return e_({ value: e, stream: n }, function(s) {
      var i = t(s.value)(s.stream);
      return ke(i) ? z(pt(s.stream, i.left.expected, i.left.fatal)) : r(n)(i.right);
    });
  };
}, b_ = function(e, t) {
  return ir(e, t);
}, R = function(e) {
  return function(t) {
    return ht(t, e);
  };
}, je = Qr, be = function(e) {
  return function(t) {
    return or(t, e);
  };
}, tn = function(e) {
  return function(t) {
    return or(t, function(r) {
      return ht(e(r), function() {
        return r;
      });
    });
  };
}, Te = function(e) {
  return function(t) {
    return b_(t, e);
  };
}, T_ = "Parser", $_ = function(e) {
  return {
    concat: function(t, r) {
      return Ys(ht(t, function(n) {
        return function(s) {
          return e.concat(n, s);
        };
      }), r);
    }
  };
}, A_ = function(e) {
  return ne(ne({}, $_(e)), { empty: Qr(e.empty) });
}, S_ = {
  URI: T_,
  map: ht,
  ap: Ys,
  chain: or,
  chainRec: en
}, Xs = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, ei = function(e) {
  return function(t) {
    return g(t, R(function(r) {
      return Xs({}, e, r);
    }));
  };
}, Er = function(e, t) {
  return function(r) {
    return g(r, be(function(n) {
      return g(t(n), R(function(s) {
        return Xs(n, e, s);
      }));
    }));
  };
}, E_ = function(e) {
  return ji(e)(e.empty);
}, x_ = Qs(Rr), Oe = function(e) {
  return k(j(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, w_ = function(e) {
  return k(j(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, rn = function(e) {
  return x_(vt(e));
}, vt = function(e) {
  return g(Yr(e), R(function(t) {
    return t.join("");
  }));
}, nn = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, xr = k(j(nn), "a digit"), R_ = /^\s$/, ti = function(e) {
  return R_.test(e);
}, ri = k(j(ti), "a whitespace"), k_ = function(e) {
  return e === "_";
}, mt = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, ni = function(e) {
  return mt(e) || nn(e) || k_(e);
};
k(j(ni), "a word character");
k(j(mt), "a letter");
var j_ = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
k(j(j_), "an unicode letter");
var ai = function(e) {
  return mt(e) && e === e.toUpperCase();
};
k(j(ai), "an upper case letter");
var si = function(e) {
  return mt(e) && e === e.toLowerCase();
};
k(j(si), "a lower case letter");
k(j(ae(nn)), "a non-digit");
var ii = k(j(ae(ti)), "a non-whitespace character");
k(j(ae(ni)), "a non-word character");
k(j(ae(mt)), "a non-letter character");
k(j(ae(ai)), "anything but an upper case letter");
k(j(ae(si)), "anything but a lower case letter");
var oi = function(e) {
  return k(S_.chainRec(e, function(t) {
    return g(I_(0, t), Kr(function() {
      return je(z(e));
    }, function(r) {
      return g(Oe(r), be(function() {
        return je(se(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, wr = E_(A_(Rr)), rt = Qs(Rr), O_ = function(e) {
  return rt(C_(e));
}, C_ = function(e) {
  return g(Yr(e), R(function(t) {
    return t.join("");
  }));
}, I_ = function(e, t) {
  return e < t.length ? lt(t.charAt(e)) : ar;
}, N_ = rn(ri);
vt(ri);
rn(ii);
vt(ii);
var M_ = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? ar : lt(t);
};
k(g(wr([rt(Oe("-")), vt(xr)]), R(function(e) {
  return +e;
})), "an integer");
k(g(wr([rt(Oe("-")), rn(xr), rt(wr([Oe("."), vt(xr)]))]), be(function(e) {
  return g(M_(e), Kr(function() {
    return d_();
  }, Qr));
})), "a float");
var P_ = Js(Oe('"'))(O_(ir(oi('\\"'), function() {
  return w_('"');
})));
function ci(e) {
  return function(t) {
    return t(o_(e.split("")));
  };
}
const Yn = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), V_ = ["#", ":"], Ce = Js(N_), an = (e) => (t) => t.join(e), sn = (e) => g(
  g_((t) => e.includes(t)),
  R(an(""))
), D_ = Y(
  sn,
  tn(() => j(() => !0))
), ui = g(
  P_,
  R((e) => JSON.parse(`"${e}"`))
), L_ = g(
  Ce(ui),
  Te(
    () => g(
      sn([":", `
`]),
      R(ia)
    )
  )
), U_ = g(
  Ce(ui),
  Te(
    () => g(
      sn([`
`]),
      R(ia)
    )
  )
), li = g(
  rt(oi("#")),
  R(ae(oa))
), di = g(
  Ce(li),
  ei("commented"),
  Er("key", () => Ce(L_)),
  tn(() => Oe(":")),
  Er("value", () => U_)
), H_ = g(
  Ce(li),
  ei("commented"),
  Er("key", () => ir(
    D_([`
`]),
    () => g(
      Xr(j((e) => !0), Jr()),
      R(Y(
        Fs,
        an("")
      ))
    )
  )),
  R(Y(
    Ks(({ key: e }) => !oa(e))
  ))
), Z_ = g(
  Xr(Ce(di), Jr())
), q_ = g(
  Xr(
    ir(
      g(di, R(lt)),
      () => g(
        H_,
        R(Y(
          Sr((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    Jr()
  ),
  R(Y(
    Hg(Y(
      Ks(Jg),
      Sr((e) => e.value)
    ))
  ))
), B_ = (e) => {
  if (V_.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, Xn = (e) => B_(e) ? JSON.stringify(e) : e, lb = (e) => g(
  e,
  Kg(
    Y(
      Yn("key", Xn),
      Yn("value", Xn),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  an(`
`)
), G_ = (e) => g(
  q_,
  ci(e),
  sr((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  dt(
    ({ value: t }) => g(
      t,
      zs(
        ({ key: r, value: n, commented: s }) => ({
          active: !s,
          key: r,
          value: n
        })
      )
    )
  )
), db = (e) => g(
  Z_,
  ci(e),
  sr((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  dt(
    ({ value: t }) => g(
      t,
      zs(
        ({ key: r, value: n, commented: s }) => ({
          active: !s,
          key: r,
          value: n
        })
      )
    )
  )
), fb = Y(
  G_,
  dt(Fs),
  Wr(() => [])
), z_ = a.object({
  id: a.optional(a.string()),
  name: a.string(),
  variables: a.array(
    a.object({
      key: a.string(),
      value: a.string()
    })
  )
}), ea = A({
  initial: !0,
  schema: z_
}), fi = () => Math.random().toString(36).substring(2, 16), F_ = a.object({
  v: a.literal(1),
  id: a.string(),
  name: a.string(),
  variables: a.array(
    a.union([
      a.object({
        key: a.string(),
        secret: a.literal(!0)
      }),
      a.object({
        key: a.string(),
        value: a.string(),
        secret: a.literal(!1).catch(!1)
      })
    ])
  )
}), K_ = A({
  initial: !1,
  schema: F_,
  up(e) {
    return {
      ...e,
      v: 1,
      id: e.id || fi(),
      variables: e.variables.map((r) => ({
        ...r,
        secret: !1
      }))
    };
  }
}), pi = [
  // Common
  {
    key: "$guid",
    description: "A v4 style GUID.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "";
      for (let r = 0; r < 36; r++)
        r === 8 || r === 13 || r === 18 || r === 23 ? t += "-" : r === 14 ? t += "4" : r === 19 ? t += e.charAt(8 + Math.floor(Math.random() * 4)) : t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$timestamp",
    description: "The current UNIX timestamp in seconds.",
    getValue: () => Math.floor(Date.now() / 1e3).toString()
  },
  {
    key: "$isoTimestamp",
    description: "The current ISO timestamp at zero UTC.",
    getValue: () => (/* @__PURE__ */ new Date()).toISOString()
  },
  {
    key: "$randomUUID",
    description: "A random 36-character UUID.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "";
      for (let r = 0; r < 36; r++)
        r === 8 || r === 13 || r === 18 || r === 23 ? t += "-" : t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  // Text, numbers, and colors
  {
    key: "$randomAlphaNumeric",
    description: "A random alphanumeric character.",
    getValue: () => {
      const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      return e.charAt(Math.floor(Math.random() * e.length));
    }
  },
  {
    key: "$randomBoolean",
    description: "A random boolean value.",
    getValue: () => Math.random() < 0.5 ? "true" : "false"
  },
  {
    key: "$randomInt",
    description: "A random integer between 0 and 1000.",
    getValue: () => Math.floor(Math.random() * 1e3).toString()
  },
  {
    key: "$randomColor",
    description: "A random color.",
    getValue: () => {
      const e = ["red", "green", "blue", "yellow", "purple", "orange"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomHexColor",
    description: "A random hex value.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "#";
      for (let r = 0; r < 6; r++)
        t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$randomAbbreviation",
    description: "A random abbreviation.",
    getValue: () => {
      const e = [
        "SQL",
        "PCI",
        "JSON",
        "HTML",
        "CSS",
        "JS",
        "TS",
        "API"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // Internet and IP addresses
  {
    key: "$randomIP",
    description: "A random IPv4 address.",
    getValue: () => Array.from(
      { length: 4 },
      () => Math.floor(Math.random() * 256)
    ).join(".")
  },
  {
    key: "$randomIPV6",
    description: "A random IPv6 address.",
    getValue: () => Array.from(
      { length: 8 },
      () => Math.floor(Math.random() * 65536).toString(16)
    ).join(":")
  },
  {
    key: "$randomMACAddress",
    description: "A random MAC address.",
    getValue: () => Array.from(
      { length: 6 },
      () => Math.floor(Math.random() * 256).toString(16)
    ).join(":")
  },
  {
    key: "$randomPassword",
    description: "A random 15-character alphanumeric password.",
    getValue: () => {
      const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let t = "";
      for (let r = 0; r < 15; r++)
        t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$randomLocale",
    description: "A random two-letter language code (ISO 639-1).",
    getValue: () => {
      const e = ["ny", "sr", "si"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomUserAgent",
    description: "A random user agent.",
    getValue: () => {
      const e = [
        "Mozilla/5.0 (Macintosh; U; Intel Mac OS X 10.9.8; rv:15.6) Gecko/20100101 Firefox/15.6.6",
        "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:15.6) Gecko/20100101 Firefox/15.6.6",
        "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:15.6) Gecko/20100101 Firefox/15.6.6"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomProtocol",
    description: "A random internet protocol.",
    getValue: () => {
      const e = ["http", "https"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomSemver",
    description: "A random semantic version number.",
    getValue: () => Array.from(
      { length: 3 },
      () => Math.floor(Math.random() * 10)
    ).join(".")
  },
  // Names
  {
    key: "$randomFirstName",
    description: "A random first name.",
    getValue: () => {
      const e = [
        "Ethan",
        "Chandler",
        "Megane",
        "John",
        "Jane",
        "Alice",
        "Bob"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomLastName",
    description: "A random last name.",
    getValue: () => {
      const e = [
        "Schaden",
        "Schneider",
        "Willms",
        "Doe",
        "Smith",
        "Johnson"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomFullName",
    description: "A random first and last name.",
    getValue: () => {
      const e = [
        "Ethan",
        "Chandler",
        "Megane",
        "John",
        "Jane",
        "Alice",
        "Bob"
      ], t = [
        "Schaden",
        "Schneider",
        "Willms",
        "Doe",
        "Smith",
        "Johnson"
      ];
      return `${e[Math.floor(Math.random() * e.length)]} ${t[Math.floor(Math.random() * t.length)]}`;
    }
  },
  {
    key: "$randomNamePrefix",
    description: "A random name prefix.",
    getValue: () => {
      const e = ["Dr.", "Ms.", "Mr.", "Mrs.", "Miss", "Prof."];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomNameSuffix",
    description: "A random name suffix.",
    getValue: () => {
      const e = ["I", "MD", "DDS", "PhD", "Esq.", "Jr."];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // Addresses
  {
    key: "$randomCity",
    description: "A random city name.",
    getValue: () => {
      const e = [
        "New York",
        "Los Angeles",
        "Chicago",
        "Houston",
        "Phoenix",
        "Philadelphia"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // profession
  {
    key: "$randomJobArea",
    description: "A random job area.",
    getValue: () => {
      const e = [
        "Mobility",
        "Intranet",
        "Configuration",
        "Development",
        "Design",
        "Testing"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobDescriptor",
    description: "A random job descriptor.",
    getValue: () => {
      const e = [
        "Forward",
        "Corporate",
        "Senior",
        "Junior",
        "Lead",
        "Principal"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobTitle",
    description: "A random job title.",
    getValue: () => {
      const e = [
        "International Creative Liaison",
        "Global Branding Officer",
        "Dynamic Data Specialist",
        "Internal Communications Consultant",
        "Productivity Analyst",
        "Regional Applications Developer"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobType",
    description: "A random job type.",
    getValue: () => {
      const e = ["Supervisor", "Manager", "Coordinator", "Director"];
      return e[Math.floor(Math.random() * e.length)];
    }
  }
  // TODO: Support various other predefined variables
], W_ = a.object({
  v: a.number()
}), pb = st({
  latestVersion: 1,
  versionMap: {
    0: ea,
    1: K_
  },
  getVersion(e) {
    const t = W_.safeParse(e);
    return t.success ? t.data.v : ea.schema.safeParse(e).success ? 0 : null;
  }
}), Pt = /<<([^>]*)>>/g, Vt = 10, hi = "ENV_EXPAND_LOOP", ta = 1;
function Q_(e, t) {
  let r = e, n = 0;
  for (; r.match(Pt) != null && n <= Vt; )
    r = r.replace(Pt, (s) => {
      const i = s.replace(/[<>]/g, ""), o = pi.find(
        (u) => u.key === i
      );
      if (o)
        return o.getValue();
      const c = t.find((u) => u.key === i);
      return c && "value" in c ? c.value : s;
    }), n++;
  return n > Vt ? se(hi) : z(r);
}
const hb = (e, t) => g(
  Q_(e, t),
  Wr(() => e)
);
function J_(e, t, r = !1, n = !1) {
  if (!t || !e)
    return z(e);
  let s = e, i = 0, o = !1;
  for (; s.match(Pt) != null && i <= Vt && !o; )
    s = decodeURI(encodeURI(s)).replace(Pt, (c, u) => {
      const l = pi.find(
        (_) => _.key === u
      );
      if (l)
        return l.getValue();
      const f = t.find((_) => _ && _.key === u);
      return f && "value" in f ? f.secret && n ? (o = !0, `<<${u}>>`) : f.secret && r ? "*".repeat(
        f.value.length
      ) : f.value : "";
    }), i++;
  return i > Vt ? se(hi) : z(s);
}
const vb = (e, t, r = !1, n = !1) => g(
  J_(e, t, r, n),
  Wr(() => e)
), Y_ = (e) => ({
  key: e.key,
  value: e.value,
  secret: !1
}), mb = (e) => {
  if (e.v && e.v === ta) return e;
  const t = e.id || fi(), r = e.name ?? "Untitled", n = (e.variables ?? []).map(Y_);
  return {
    v: ta,
    id: t,
    name: r,
    variables: n
  };
}, X_ = a.array(
  a.union([
    a.object({
      key: a.string(),
      value: a.string(),
      secret: a.literal(!1)
    }),
    a.object({
      key: a.string(),
      secret: a.literal(!0)
    }),
    a.object({
      key: a.string(),
      value: a.string()
    })
  ])
), ra = A({
  initial: !0,
  schema: X_
}), eb = a.object({
  v: a.literal(1),
  variables: a.array(
    a.union([
      a.object({
        key: a.string(),
        secret: a.literal(!0)
      }),
      a.object({
        key: a.string(),
        value: a.string(),
        secret: a.literal(!1)
      })
    ])
  )
}), tb = A({
  initial: !1,
  schema: eb,
  up(e) {
    return {
      v: 1,
      variables: e.map((r) => "value" in r ? {
        key: r.key,
        value: r.value,
        secret: !1
      } : {
        key: r.key,
        secret: !0
      })
    };
  }
}), rb = a.object({
  v: a.number()
}), yb = st({
  latestVersion: 1,
  versionMap: {
    0: ra,
    1: tb
  },
  getVersion(e) {
    const t = rb.safeParse(e);
    return t.success ? t.data.v : ra.schema.safeParse(e).success ? 0 : null;
  }
});
export {
  Ya as AuthCodeGrantTypeParams,
  Ky as ClientCredentialsGrantTypeParams,
  Mg as CollectionSchemaVersion,
  pb as Environment,
  ta as EnvironmentSchemaVersion,
  fs as FormDataKeyValue,
  xs as GQLHeader,
  Cs as GQL_REQ_SCHEMA_VERSION,
  yb as GlobalEnvironment,
  pi as HOPP_SUPPORTED_PREDEFINED_VARIABLES,
  ub as HoppCollection,
  Os as HoppGQLAuth,
  ut as HoppGQLAuthAPIKey,
  zr as HoppGQLAuthAWSSignature,
  ye as HoppGQLAuthBasic,
  ge as HoppGQLAuthBearer,
  _e as HoppGQLAuthInherit,
  me as HoppGQLAuthNone,
  gs as HoppGQLAuthOAuth2,
  Fr as HoppGQLRequest,
  rr as HoppRESTAuth,
  ot as HoppRESTAuthAPIKey,
  er as HoppRESTAuthAWSSignature,
  pe as HoppRESTAuthBasic,
  he as HoppRESTAuthBearer,
  cs as HoppRESTAuthDigest,
  ve as HoppRESTAuthInherit,
  fe as HoppRESTAuthNone,
  gs as HoppRESTAuthOAuth2,
  ct as HoppRESTHeaders,
  Xt as HoppRESTParams,
  qr as HoppRESTReqBody,
  nr as HoppRESTRequest,
  Qy as HoppRESTRequestResponse,
  _s as HoppRESTRequestResponses,
  Kt as HoppRESTRequestVariables,
  Wy as HoppRESTResponseOriginalRequest,
  it as ImplicitOauthFlowParams,
  os as PasswordGrantTypeParams,
  bs as RESTReqSchemaVersion,
  nb as ValidContentTypesList,
  Us as generateUniqueRefId,
  bg as getDefaultGQLRequest,
  rg as getDefaultRESTRequest,
  ab as isEqualHoppRESTRequest,
  ob as isHoppRESTRequest,
  Xy as knownContentTypes,
  Bs as makeCollection,
  cb as makeGQLRequest,
  ib as makeRESTRequest,
  hb as parseBodyEnvVariables,
  Q_ as parseBodyEnvVariablesE,
  fb as parseRawKeyValueEntries,
  G_ as parseRawKeyValueEntriesE,
  vb as parseTemplateString,
  J_ as parseTemplateStringE,
  lb as rawKeyValueEntriesToString,
  sb as safelyExtractRESTRequest,
  db as strictParseRawKeyValueEntriesE,
  Tg as translateToGQLRequest,
  mb as translateToNewEnvironment,
  Y_ as translateToNewEnvironmentVariables,
  Vg as translateToNewGQLCollection,
  Pg as translateToNewRESTCollection,
  ng as translateToNewRequest
};
